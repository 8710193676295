import { useMemo } from 'react'
import {
  type VenueSettingsOptions,
  AdditionalConfigEnum,
  ContactInfoEnum,
  SeatingAreaEnum,
  WaitlistEnabledOptionEnum,
} from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { CheckboxGroup, RadioGroup, Label, FormSelect, Checkbox, type CheckboxChoice, FormToggle } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack, Box, SettingsSection, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { SectionBox } from '../components'
import { PartySizeRangePicker } from '../components/PartySizeRangePicker'
import { PriorityAlertsTiers } from '../components/PriorityAlertsTiers'
import { venueSettingsMessages } from '../VenueSettings.locales'
import { reservationsMessages } from './Reservations.locales'
import type { ReservationsDiningForm, ContactInfoDiningValues } from './ReservationsDining.zod'

export interface ReservationsDiningProps {
  field: Field<ReservationsDiningForm>
  options: VenueSettingsOptions
}

export function ReservationsDining({ field, options }: ReservationsDiningProps) {
  const { formatMessage } = useLocales()
  const autoConfirmAllowed = useWatch(field.prop('autoConfirmAllowed'))
  const multiReservation = useWatch(field.prop('multiReservation'))
  const priorityAlertsEnabled = useWatch(field.prop('priorityAlertsEnabled'))
  const priorityAlertsTieringEnabled = useWatch(field.prop('priorityAlertsTieringEnabled'))
  const { venue } = useVenueContext()
  const { additionalConfigChoices, contactInfoChoices } = useMemo(
    () => ({
      contactInfoChoices: [
        { value: ContactInfoEnum.phone, label: formatMessage(reservationsMessages.phone) },
        { value: ContactInfoEnum.email, label: formatMessage(reservationsMessages.email) },
        { value: ContactInfoEnum.phoneoremail, label: formatMessage(reservationsMessages.phoneOrEmail) },
        { value: ContactInfoEnum.lastname, label: formatMessage(reservationsMessages.lastName) },
        { value: ContactInfoEnum.salutation, label: formatMessage(reservationsMessages.salutation) },
      ] as Array<CheckboxChoice<ContactInfoDiningValues>>,
      additionalConfigChoices: [
        {
          value: AdditionalConfigEnum.autotables,
          label: formatMessage(reservationsMessages.autoAssignTablesTitle),
          description: formatMessage(reservationsMessages.autoAssignTablesDescription),
        },
        {
          value: AdditionalConfigEnum.showproblem,
          label: formatMessage(reservationsMessages.showProblemReservationsTitle),
          description: formatMessage(reservationsMessages.showProblemReservationsDescription),
        },
      ],
    }),
    [formatMessage]
  )

  const waitlistIntervalOptions = useMemo(
    () =>
      options.waitlistWaitInterval.map(option => ({
        id: String(option),
        label: formatMessage(commonMessages.minutes, { count: option }),
      })),
    [formatMessage, options.waitlistWaitInterval]
  )

  const multiReservationOptions = [
    {
      value: '0',
      label: formatMessage(reservationsMessages.multiReservationTimeBased),
    },
    {
      value: '1',
      label: formatMessage(reservationsMessages.multiReservationShiftBased),
    },
  ]

  const seatingAreaChoices = useMemo(
    () => [
      {
        value: SeatingAreaEnum.ALL,
        label: formatMessage(reservationsMessages.seatingAreaAll),
      },
      ...options.seatingAreas,
    ],
    [formatMessage, options.seatingAreas]
  )

  return (
    <VStack spacing="lm" mt="lm">
      <SectionBox halfsize title={formatMessage(reservationsMessages.sectionReservationBooking)}>
        <VStack spacing="s" mb="l">
          <Label
            primary={formatMessage(reservationsMessages.contactInfoTitle)}
            secondary={formatMessage(reservationsMessages.contactInfoDescription)}
          />
          <CheckboxGroup field={field.prop('contactInfo')} data-test="contactInfo" choices={contactInfoChoices} />
        </VStack>
        <SettingsSection
          collapsible
          defaultCollapsed
          size="s"
          memoCollapseState="generalSettingsReservationsDining"
          data-test="reservationsDiningSection"
          title={formatMessage(venueSettingsMessages.additionalConfig)}
        >
          <Box pt="m">
            <CheckboxGroup
              field={field.prop('additionalConfig')}
              data-test="additionalConfig"
              choices={additionalConfigChoices}
              spacing="m"
            />
            <Checkbox
              description={formatMessage(reservationsMessages.multiReservationDescription)}
              field={field.prop('multiReservation')}
              data-test="multiReservation"
            >
              {formatMessage(reservationsMessages.multiReservationTitle)}
            </Checkbox>
            {multiReservation && (
              <Box pt="s" pl="lm">
                <RadioGroup
                  field={field.prop('multiReservationOption')}
                  data-test="multiReservationOptions"
                  choices={multiReservationOptions}
                />
              </Box>
            )}
          </Box>
        </SettingsSection>
      </SectionBox>
      <SectionBox halfsize title={formatMessage(reservationsMessages.sectionInternalConfigurations)}>
        <Label
          primary={formatMessage(reservationsMessages.seatingAreaTitle)}
          secondary={formatMessage(reservationsMessages.seatingAreaDescription)}
        />
        <RadioGroup choices={seatingAreaChoices} field={field.prop('defaultSeatingArea')} data-test="defaultSeatingArea" />
        <Box pt="l">
          <Checkbox
            field={field.prop('showCovers')}
            data-test="showCovers"
            description={formatMessage(reservationsMessages.showCoversDescription)}
          >
            {formatMessage(reservationsMessages.showCoversTitle)}
          </Checkbox>
        </Box>
      </SectionBox>
      {options.priorityAlertsAdminEnabled && (
        <SectionBox halfsize title={formatMessage(reservationsMessages.sectionReservationPriorityAlerts)}>
          <FormToggle
            label={formatMessage(reservationsMessages.priorityAlertsOptsTitle)}
            description={formatMessage(reservationsMessages.priorityAlertsDescription)}
            field={field.prop('priorityAlertsEnabled')}
            data-test="priorityAlertsEnabled"
            onChange={value => {
              if (!value) {
                field.prop('priorityAlertsTieringEnabled').set(false)
                field.prop('priorityAlertsQuietHoursEnabled').set(false)
              }
            }}
          />
          {priorityAlertsEnabled && (
            <Box pt="s">
              <PartySizeRangePicker field={field.prop('priorityAlertsPartySize')} />
              {options.remindersSmsEnabled && options.priorityAlertsSmsEnabled && (
                <Box mt="lm">
                  <FormToggle
                    label={formatMessage(reservationsMessages.priorityAlertsQuietHoursTitle)}
                    description={formatMessage(reservationsMessages.priorityAlertsQuietHoursDescription)}
                    field={field.prop('priorityAlertsQuietHoursEnabled')}
                    data-test="priorityAlertsQuietHoursEnabled"
                  />
                </Box>
              )}
              {options.priorityAlertsTieringAdminEnabled && (
                <>
                  <DividerLine mt="lm" mb="lm" />
                  <FormToggle
                    label={formatMessage(reservationsMessages.tieredPriorityAlertsOptsTitle)}
                    field={field.prop('priorityAlertsTieringEnabled')}
                    data-test="priorityAlertsTieringEnabled"
                  />
                  {priorityAlertsTieringEnabled && <PriorityAlertsTiers field={field} venueUrlKey={venue.urlKey} />}
                </>
              )}
            </Box>
          )}
        </SectionBox>
      )}
      {options.waitlistEnabled !== WaitlistEnabledOptionEnum.DISABLED && (
        <SectionBox halfsize title={formatMessage(reservationsMessages.sectionReservationWaitTime)}>
          <Label
            primary={formatMessage(reservationsMessages.waitTimeOptsTitle)}
            secondary={formatMessage(reservationsMessages.waitTimeOptsDescription)}
          />
          <HStack spacing="s">
            <Label primary={formatMessage(reservationsMessages.maxWaitTimeTitle)}>
              <FormSelect
                options={options.waitlistWaitTime}
                searchable={false}
                hideErrorMessage
                field={field.prop('maxWaitTime')}
                data-test="maxWaitTime"
              />
            </Label>
            <Label
              primary={formatMessage(reservationsMessages.withIntervalTitle)}
              info={
                <VStack spacing="s">
                  <Box>
                    <Text color="lightFont" fontWeight="bold">
                      {formatMessage(reservationsMessages.withIntervalInfoTitle)}
                    </Text>
                  </Box>
                  <Box>{formatMessage(reservationsMessages.withIntervalInfo)}</Box>
                </VStack>
              }
              data-test="withIntervalTitle"
            >
              <FormSelect
                options={waitlistIntervalOptions}
                searchable={false}
                hideErrorMessage
                field={field.prop('withInterval')}
                data-test="withInterval"
              />
            </Label>
          </HStack>
          <Box pt="l">
            <Label
              primary={formatMessage(reservationsMessages.autoRemoveTimingTitle)}
              secondary={formatMessage(reservationsMessages.autoRemoveTimingDescription)}
            >
              <HStack>
                <FormSelect
                  options={options.waitlistAutoRemoveTime}
                  searchable={false}
                  hideErrorMessage
                  field={field.prop('autoRemoveTiming')}
                  data-test="autoRemoveTiming"
                />
              </HStack>
            </Label>
          </Box>
        </SectionBox>
      )}
      <SectionBox halfsize title={formatMessage(reservationsMessages.sectionReservationConfirmation)}>
        <Checkbox
          description={formatMessage(reservationsMessages.autoConfirmReservationsDescription)}
          field={field.prop('autoConfirmAllowed')}
          data-test="autoConfirmAllowed"
        >
          {formatMessage(reservationsMessages.autoConfirmReservationsTitle)}
        </Checkbox>
        {autoConfirmAllowed && (
          <Box pt="s" pl="lm">
            <RadioGroup field={field.prop('autoConfirm')} data-test="autoConfirm" choices={options.autoConfirm} />
          </Box>
        )}
      </SectionBox>
    </VStack>
  )
}
