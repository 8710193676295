import { useMemo } from 'react'
import type { LanguageStrings, VenueLanguage } from '@sevenrooms/core/domain'
import { z, type ZodSchema } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useImageForm } from '@sevenrooms/core/ui-kit/form'
import { generalMessages } from './General/General.locales'

export type GeneralForm = z.infer<ReturnType<typeof useGeneralForm>>
export type CheckoutConfirmationForm = ZodSchema<typeof useCheckoutConfirmationForm>
export type SearchAvailabilityForm = ZodSchema<typeof useSearchAvailabilityForm>

export function useCheckoutConfirmationForm() {
  return z.object({
    aboveAgeConsentOn: z.boolean(),
    ageToConsent: z.number(),
    displayReservationSmsOptIn: z.boolean(),
    reservationSmsOptInOn: z.boolean(),
    enablePromoCodes: z.boolean(),
    enableSocialMediaLogin: z.boolean(),
    customCheckoutPolicyOn: z.boolean(),
    isDefaultCustomCheckoutPolicyOn: z.boolean(),
    venueSmsMarketingOn: z.boolean(),
    isVenueSmsMarketingPolicyDefaultOn: z.boolean(),
    venueSpecificMarketingOn: z.boolean(),
    isVenueSpecificMarketingPolicyDefaultOn: z.boolean(),
    venueGroupMarketingOn: z.boolean(),
    isVenueGroupMarketingPolicyDefaultOn: z.boolean(),
    birthdayTypeOn: z.boolean(),
    birthdayType: z.string(),
    enableDietaryRestrictions: z.boolean(),
    requireDietaryTagGdprOptIn: z.boolean(),
    enableGiftCardRedemption: z.boolean(),
    enableAdditionalProfileInfoConfirmation: z.boolean(),
    enableSpecialOccasionsConfirmation: z.boolean(),
    enableDietaryRestrictionsConfirmation: z.boolean(),
    requireDietaryTagGdprOptInConfirmation: z.boolean(),
    enableSpecialOccasions: z.boolean(),
    postalCodeType: z.string(),
    postalCodeTypeOn: z.boolean(),
    salutationType: z.string(),
    salutationTypeOn: z.boolean(),
    recaptchaOn: z.boolean(),
    remindersSmsEnabled: z.boolean(),
    smsBookingNotificationEnabled: z.boolean(),
    showSmsOptInSettingsForTwilioUs: z.boolean(),
    isSmsMarketingEnabled: z.boolean(),
    textCustomGdprPolicyLink: z.string().nullable(),
    textCustomPolicyHolderName: z.string().nullable(),
    textCustomPrivacyPolicyLink: z.string().nullable(),
  })
}

export function useSearchAvailabilityForm() {
  return z
    .object({
      guests: z
        .object({
          min: z.number(),
          max: z.number(),
        })
        .superRefine(({ min, max }, ctx) => {
          if (min > max) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'Min Guests should be less than or equal to Max Guests',
            })
          }
        }),
      availabilityTimeRange: z.string(),
      seating: z
        .object({
          min: z.string().nullable(),
          max: z.string().nullable(),
        })
        .superRefine(({ min, max }, ctx) => {
          if (min && max && min > max) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: 'First Seating time should be before or the same as Last Seating time',
            })
          }
        }),
      maxDaysOut: z.number().min(1, 'Please enter a number between 1 and 999').max(999, 'Please enter a number between 1 and 999'),
      specificTimeSlotsArray: z.array(z.string()),
      enableRequestsType: z.string(),
      enableRequests: z.boolean(),
      priorityAlertsEnabled: z.boolean(),
      reservationHoldTimeMins: z.number().nullable(),
      reservationHoldEnabled: z.boolean(),
      isFeesInPriceDisplayed: z.boolean(),
    })
    .superRefine(({ specificTimeSlotsArray, availabilityTimeRange, seating, reservationHoldTimeMins, reservationHoldEnabled }, ctx) => {
      if (availabilityTimeRange === 'specificTimeSlots' && specificTimeSlotsArray.length === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['specificTimeSlotsArray'],
          message: 'Please select at least one time slot',
        })
      } else if (availabilityTimeRange === 'customTimeRange' && (seating.max == null || seating.min == null)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['seating'],
          message: 'Please select slots',
        })
      }
      if (reservationHoldEnabled && (reservationHoldTimeMins === null || reservationHoldTimeMins < 1)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['reservationHoldTimeMins'],
          message: 'Required',
        })
      }
    })
}

export type ReservationSettingsForm = z.infer<ReturnType<typeof useReservationSettingsForm>>

export function useGeneralForm(defaultLanguage: VenueLanguage) {
  const image = useImageForm()
  const { formatMessage } = useLocales()

  return z
    .object({
      languageStrings: z.custom<LanguageStrings>(),
      showSpecialAttentionMessage: z.boolean(),
      confirmationIncludeSpecialAttentionMessage: z.boolean(),
      logoImg: image.nullable(),
      headerImg: image.nullable(),
      primaryColor: z.string(),
      secondaryColor: z.string(),
      enablePrivateEventsBanner: z.boolean(),
      bannerImg: image.nullable(),
      newResWidgetPrivateEventsEnabled: z.boolean(),
    })
    .superRefine((value, ctx) => {
      const specialAttentionLabel = value.languageStrings[defaultLanguage.value].resWidgetSpecialAttentionLabel?.trim() || ''
      const specialAttentionInfoBody = value.languageStrings[defaultLanguage.value].resWidgetSpecialAttentionInfoBody?.trim() || ''

      if (specialAttentionLabel.length === 0) {
        if (value.showSpecialAttentionMessage || value.confirmationIncludeSpecialAttentionMessage) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(generalMessages.specialAttentionMainHeadlineErrorMessage),
            path: [`languageStrings[${defaultLanguage.value}].resWidgetSpecialAttentionLabel`],
          })
        } else if (
          specialAttentionInfoBody.length > 0 &&
          !value.showSpecialAttentionMessage &&
          !value.confirmationIncludeSpecialAttentionMessage
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: formatMessage(generalMessages.specialAttentionMainHeadlinePopupSetErrorMessage),
            path: [`languageStrings[${defaultLanguage.value}].resWidgetSpecialAttentionLabel`],
          })
        }
      }
    })
}

export function useReservationSettingsForm(defaultLanguage: VenueLanguage) {
  const checkoutConfirmation = useCheckoutConfirmationForm()
  const searchAvailability = useSearchAvailabilityForm()
  const general = useGeneralForm(defaultLanguage)
  return useMemo(
    () =>
      z.object({
        checkoutConfirmation,
        searchAvailability,
        general,
      }),
    [checkoutConfirmation, searchAvailability, general]
  )
}
