import { defineMessages } from '@sevenrooms/core/locales'

export const searchAvailability = defineMessages({
  searchCriteria: {
    id: 'searchCriteria',
    defaultMessage: 'Search Criteria',
  },
  guestPickerRange: {
    id: 'guestPickerRange',
    defaultMessage: 'Guest Picker: Searchable Range',
  },
  guestPickerRangeDescr: {
    id: 'guestPickerRangeDescr',
    defaultMessage: 'Party size range from which a guest can select.',
  },
  minGuest: {
    id: 'minGuest',
    defaultMessage: 'Min Guests',
  },
  maxGuest: {
    id: 'maxGuest',
    defaultMessage: 'Max Guests',
  },
  timePickerIncrements: {
    id: 'timePickerIncrements',
    defaultMessage: 'Time Picker: Searchable Increments',
  },
  timePickerIncrementsDescr: {
    id: 'timePickerIncrementsDescr',
    defaultMessage: 'Increments of time slots from which a guest can select.',
  },
  customTimeRange: {
    id: 'customTimeRange',
    defaultMessage: 'Range of Time Slots in :00 and :30 increments',
  },
  specificTimeSlots: {
    id: 'specificTimeSlots',
    defaultMessage: 'Specific Time Slot(s) in :00, :15, :30, :45 increments',
  },
  all: {
    id: 'all',
    defaultMessage: 'All Time Slots in :00 and :30 increments',
  },
  firstSeating: {
    id: 'firstSeating',
    defaultMessage: 'First Seating',
  },
  lastSeating: {
    id: 'lastSeating',
    defaultMessage: 'Last Seating',
  },
  datePickerCalendarLength: {
    id: 'datePickerCalendarLength',
    defaultMessage: 'Date Picker: Searchable Timeframe',
  },
  datePickerCalendarLengthDescr: {
    id: 'datePickerCalendarLengthDescr',
    defaultMessage: 'Number of days into the future a guest can select a date in the calendar.',
  },
  reservationRequests: {
    id: 'reservationRequests',
    defaultMessage: 'Reservation Requests',
  },
  reservationRequestsDescr: {
    id: 'reservationRequestsDescr',
    defaultMessage: 'Guests can Request a reservation when there is no availability',
  },
  priorityAlertRequests: {
    id: 'priorityAlertRequests',
    defaultMessage: 'Priority Alerts and Requests',
  },
  priorityAlertRequestsDescr: {
    id: 'priorityAlertRequestsDescr',
    defaultMessage: 'Guests can set a Priority Alert or Request when there is no availability',
  },
  availabilityConfigurations: {
    id: 'availabilityConfigurations',
    defaultMessage: 'Availability Configurations',
  },
  displayAdditionalFeesInPrice: {
    id: 'displayAdditionalFeesInPrice',
    defaultMessage: 'Display additional fees in price',
  },
  displayAdditionalFeesInPriceDescr: {
    id: 'displayAdditionalFeesInPriceDescr',
    defaultMessage:
      'Includes fees on Access Rules, Shifts, or Upgrades, specifically Service Charge and Forced Gratuity. Taxes are excluded.',
  },
  learnMore: {
    id: 'learnMore',
    defaultMessage: 'Learn more',
  },
  shiftLabel: {
    id: 'shiftLabel',
    defaultMessage: 'Shift must exist at searched time',
  },
  accessRulesLabel: {
    id: 'accessRulesLabel',
    defaultMessage: 'Access Rules must be in active booking window at searched time',
  },
  selectTimeSlot: {
    id: 'selectTimeSlot',
    defaultMessage: 'Select Time Slot(s)',
  },
  accessRulesTooltip: {
    id: 'accessRulesTooltip',
    defaultMessage: 'To ensure guests cannot make requests if availability for date/time has not yet been opened',
  },
})
