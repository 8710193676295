import React, { useMemo } from 'react'
import { useAuthenticateThroughSevenRoomsMutation, useGetPOSIConfigQuery } from '@sevenrooms/core/api'
import { POSITypeEnum, type SimphonyConfig } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button, Form, FormInput, Label } from '@sevenrooms/core/ui-kit/form'
import { Box, DividerLine, HStack, notify, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, SettingsPageContent, SettingsPageMeta, SettingsPage } from '@sevenrooms/mgr-core'
import { POSICircleLogo, SettingsBanner } from '../../components'
import { LoadingSettingsNoHeader } from '../../Loading'
import { posiCommonMessages } from '../../locales/posiCommonMessages.locales'
import { posiMetaDataMap } from '../../ReferenceData'
import { messages } from './SimphonyAuthenticatePage.locales'
import {
  getSimphonyAuthenticatePageFormDefaultValues,
  type SimphonyAuthenticatePageFormData,
  useSimphonyAuthenticatePageForm,
} from './SimphonyAuthenticatePageForm.zod'

export function SimphonyAuthenticatePage() {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueId, venue } = useVenueContext()
  const { data: config, isLoading: isLoadingConfig } = useGetPOSIConfigQuery({ venueId, posiType: POSITypeEnum.SIMPHONY })
  const { logo, title: logoAltText } = posiMetaDataMap.SIMPHONY

  const [authenticate, { isLoading, error }] = useAuthenticateThroughSevenRoomsMutation()

  const defaultValues = useMemo(() => getSimphonyAuthenticatePageFormDefaultValues(config as SimphonyConfig), [config])
  const form = useForm(useSimphonyAuthenticatePageForm(), {
    defaultValues,
  })
  const { field } = form

  const handleCancel = () => {
    nav.push(routes.manager2.settings.pointOfSaleIntegration.view, { params: { venueKey: venue.urlKey } })
  }

  const handleAuthenticate = async (data: SimphonyAuthenticatePageFormData) => {
    try {
      await authenticate({ venueId, posiType: POSITypeEnum.SIMPHONY, posiAuthenticationData: data }).unwrap()
      notify({
        content: formatMessage(messages.authenticateSuccess),
        type: 'success',
      })
      nav.push(routes.manager2.settings.pointOfSaleIntegration.edit, { params: { venueKey: venue.urlKey } })
      window.location.reload()
    } catch {
      notify({
        content: formatMessage(messages.authenticateError),
        type: 'error',
      })
    }
  }

  return isLoadingConfig ? (
    <LoadingSettingsNoHeader />
  ) : (
    <Form {...form} onSubmit={data => handleAuthenticate(data)} onInvalid={() => {}}>
      <SettingsPage>
        <SettingsPageMeta title={formatMessage(messages.tabTitle)} />
        <SettingsPageContent
          title={formatMessage(messages.title)}
          description={formatMessage(messages.description, {
            a: (chunks: string[]) => (
              <Anchor target="_self" href={posiMetaDataMap.SIMPHONY.docLink}>
                {chunks}
              </Anchor>
            ),
          })}
          secondHeaderMaxWidth="1000px"
          actions={
            <HStack spacing="s" mr="s">
              <Button variant="tertiary" onClick={handleCancel} data-test="simphony-cancel-button">
                {formatMessage(commonMessages.cancel)}
              </Button>
              <Button type="submit" disabled={isLoading} data-test="simphony-submit-button">
                {formatMessage(messages.submitButtonLabel)}
              </Button>
            </HStack>
          }
        >
          <VStack m="m" backgroundColor="primaryBackground" width="1000px" maxWidth="1000px">
            {error && <SettingsBanner title={formatMessage(posiCommonMessages.error)} description={error as string} type="error" />}
            <HStack spacing="m" pt="m" pb="l" pl="l" pr="l" flexGrow="1" alignItems="center" justifyContent="space-between">
              <VStack width="1000px" spacing="s" justifyContent="center" alignItems="start">
                <HStack spacing="s" justifyContent="center" alignItems="center">
                  <POSICircleLogo logo={logo} title={logoAltText} />
                  <VStack spacing="s" pl="m" justifyContent="center" alignItems="start">
                    <Text textStyle="h2">{formatMessage(messages.authenticationInformationLabel)}</Text>
                    <Text>
                      {formatMessage(messages.authenticationInformationDescription, {
                        a: (chunks: string[]) => <Anchor href={posiMetaDataMap.SIMPHONY.docLink}>{chunks}</Anchor>,
                      })}
                    </Text>{' '}
                  </VStack>
                </HStack>

                <Box width="100%">
                  <DividerLine mr="none" ml="none" />
                </Box>
                <VStack width="100%" spacing="s" justifyContent="center" alignItems="start">
                  <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
                    <Label width="50%" primary={formatMessage(messages.usernameLabel)}>
                      <FormInput fullWidth hideErrorMessage field={field.prop('username')} />
                    </Label>
                    <Label width="50%" primary={formatMessage(messages.passwordLabel)}>
                      <FormInput fullWidth hideErrorMessage field={field.prop('password')} type="password" />
                    </Label>
                  </HStack>
                  <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
                    <Label width="50%" primary={formatMessage(messages.orgShortNameLabel)}>
                      <FormInput fullWidth hideErrorMessage field={field.prop('orgShortName')} />
                    </Label>
                    <Label width="50%" primary={formatMessage(messages.openidUrlLabel)}>
                      <FormInput fullWidth hideErrorMessage field={field.prop('openidUrl')} />
                    </Label>
                  </HStack>
                  <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
                    <Label width="50%" primary={formatMessage(messages.simphonyBaseUrlLabel)}>
                      <FormInput fullWidth hideErrorMessage field={field.prop('simphonyBaseUrl')} />
                    </Label>
                    <Label width="50%" primary={formatMessage(messages.openidClientIdLabel)}>
                      <FormInput fullWidth hideErrorMessage field={field.prop('openidClientId')} />
                    </Label>
                  </HStack>
                </VStack>
              </VStack>
            </HStack>
          </VStack>
        </SettingsPageContent>
      </SettingsPage>
    </Form>
  )
}
