import { type ReactNode, useEffect } from 'react'
import {
  useGetVenueLanguagesQuery,
  useGetReservationWidgetSettingsV2Query,
  useSetReservationWidgetSettingsV2Mutation,
} from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { generatePath } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { MainContent } from './MainContent'
import { reservationWidgetSettingsMessages } from './ReservationWidgetSettings.locales'

export function ReservationWidgetSettings() {
  const { formatMessage } = useLocales()
  const { venueId, venueKey } = useVenueContext()
  const {
    data: languageListData,
    isFetching: isLanguageListFetching,
    error: languageListError,
  } = useGetVenueLanguagesQuery({
    venueId,
  })
  const { data, isFetching, error } = useGetReservationWidgetSettingsV2Query({ venueId })
  useEffect(() => {
    if (data && !(data.general.newResWidgetEnabled && data.general.forceRedirectsOnLegacyWidget)) {
      const oldResWidgetSettingsUrl = generatePath(routes.manager.settings.widgets.reservationWidgetSettings.path, { venueKey })
      window.location.replace(oldResWidgetSettingsUrl)
    }
  }, [data, venueKey])

  const [setReservationWidgetSettingsNew, { isLoading }] = useSetReservationWidgetSettingsV2Mutation()

  const onSuccess = () => {
    notify({ content: formatMessage(reservationWidgetSettingsMessages.success), type: 'success' })
  }

  const onError = () => {
    notify({ content: formatMessage(reservationWidgetSettingsMessages.error), type: 'error' })
  }

  const renderContent = (content: ReactNode) => (
    <>
      <SettingsPageMeta title={formatMessage(reservationWidgetSettingsMessages.title)} />
      {content}
    </>
  )

  if ((isFetching && !error) || (isLanguageListFetching && !languageListError)) {
    return renderContent(<Loader />)
  }

  if (data && !error && languageListData && !languageListError) {
    return renderContent(
      <MainContent
        data={data}
        isSaving={isLoading}
        onSave={async (formData, dirtyFields) => {
          try {
            await setReservationWidgetSettingsNew({
              venueId,
              formData,
              uploadLogoImage: !!dirtyFields.general?.logoImg,
              uploadHeaderImage: !!dirtyFields.general?.headerImg,
              uploadBannerImage:
                !!dirtyFields.general?.bannerImg &&
                formData.general.enablePrivateEventsBanner &&
                formData.general.newResWidgetPrivateEventsEnabled,
            }).unwrap()
            onSuccess()
          } catch (error) {
            onError()
          }
        }}
      />
    )
  }

  return renderContent(formatMessage(reservationWidgetSettingsMessages.error))
}
