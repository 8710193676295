import React, { type Dispatch, type SetStateAction } from 'react'
import type { POSIType } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { POSICircleLogo } from '../../components'
import { posiMetaDataMap } from '../../ReferenceData'
import { ConfirmDisconnectIntegrationDialog } from '../ConfirmDisconnectIntegrationDialog'
import { messages } from './IntegrationStatusCard.locales'

export interface IntegrationStatusCardProps {
  isAuthenticated: boolean
  lastUpdatedDateString: string
  setIsDisconnecting: Dispatch<SetStateAction<boolean>>
  externalSetupLink?: string
}

export function IntegrationStatusCard({
  isAuthenticated,
  lastUpdatedDateString,
  setIsDisconnecting,
  externalSetupLink,
}: IntegrationStatusCardProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const posName = venue.posIntegrationType as POSIType
  const { logo, title } = posiMetaDataMap[posName]
  const [isConfirmDisconnectDialogOpen, setIsConfirmDisconnectDialogOpen] = React.useState(false)

  const handleEditClick = () => {
    nav.push(routes.manager2.settings.pointOfSaleIntegration.edit, { params: { venueKey: venue.urlKey } })
  }

  const handleReauthenticateClick = () => {
    const { internalSetupLink } = posiMetaDataMap[posName]
    if (internalSetupLink) {
      nav.push(internalSetupLink, { params: { venueKey: venue.urlKey } })
    }
    if (externalSetupLink) {
      window.location.href = externalSetupLink
    }
  }

  const handleDisconnectClick = () => {
    setIsConfirmDisconnectDialogOpen(true)
  }

  return (
    <>
      <VStack backgroundColor="primaryBackground" width="100%">
        <HStack spacing="m" pt="m" pb="m" pl="m" pr="m" flexGrow="1" alignItems="center" justifyContent="space-between">
          <HStack spacing="s" justifyContent="center" alignItems="center">
            <POSICircleLogo logo={logo} title={title} />
            <VStack spacing="s" pl="m" justifyContent="center" alignItems="start">
              <HStack spacing="s">
                <Text textStyle="h2">{title}</Text>
                <HStack spacing="s" alignItems="center">
                  <svg width="8" height="10">
                    <circle cx="4" cy="4" r="4" fill={isAuthenticated ? '#008a67' : '#ff943e'} />
                  </svg>
                  <span style={{ fontSize: 'x-small', letterSpacing: '3px', color: '#6b6b6b' }}>
                    {formatMessage(isAuthenticated ? messages.authenticatedLabel : messages.actionRequiredLabel)}
                  </span>
                </HStack>
              </HStack>
              <Text color="secondaryFont">{formatMessage(messages.lastUpdatedDate, { dateString: lastUpdatedDateString })}</Text>
            </VStack>
          </HStack>
          <Menu scrollLock>
            {isAuthenticated ? (
              <MenuItem onClick={() => handleEditClick()}>
                <Icon name="VMSWeb-edit" />
                {formatMessage(commonMessages.edit)}
              </MenuItem>
            ) : (
              <MenuItem onClick={() => handleReauthenticateClick()}>
                <Icon name="VMSWeb-refresh" />
                {formatMessage(messages.reauthenticate)}
              </MenuItem>
            )}
            <MenuItem onClick={() => handleDisconnectClick()}>
              <Icon name="VMSWeb-trash" />
              {formatMessage(messages.disconnect)}
            </MenuItem>
          </Menu>
        </HStack>
      </VStack>

      <ConfirmDisconnectIntegrationDialog
        isOpen={isConfirmDisconnectDialogOpen}
        onClose={() => {
          setIsConfirmDisconnectDialogOpen(false)
        }}
        setIsDisconnecting={setIsDisconnecting}
      />
    </>
  )
}
