import { TransitionBlockerWithOldRouterSupport } from 'mgr/lib/components/TransitionBlockerWithOldRouterSupport'
import { useState } from 'react'
import { VenueClassEnum, type VenueSettings, type VenueSettingsOptions } from '@sevenrooms/core/domain'
import { type Dirtied, useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, UnsavedChangesModal, notify, Tab, TabList, TabPanel, Tabs } from '@sevenrooms/core/ui-kit/layout'
import { cleanNullish } from '@sevenrooms/core/utils'
import { SettingsPageContent, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { Backwriter } from './Backwriter/Backwriter'
import { ChargesDining, ChargesNightlife } from './Charges'
import { GuestEmailSMS } from './GuestEmailSMS'
import { InternalTeamEmails } from './InternalTeamEmails'
import { ReservationsDining, ReservationsNightlife } from './Reservations'
import { venueSettingsMessages } from './VenueSettings.locales'
import { useVenueSettingsDiningForm, useVenueSettingsNightlifeForm } from './VenueSettings.zod'

export interface MainContentProps {
  fields: VenueSettings
  options: VenueSettingsOptions
  venueClass: VenueClassEnum
  onSave: (formData: VenueSettings, dirtyFields: Dirtied<VenueSettings>) => void
}

export function MainContent({ fields, options, venueClass, onSave }: MainContentProps) {
  const { formatMessage } = useLocales()
  const { venueSettings } = useVenueSettingsContext()

  const tabs: { key: string; title: string }[] = [
    {
      key: 'internalTeamEmails',
      title: formatMessage(venueSettingsMessages.tabInternalTeamEmails),
    },
    {
      key: 'guestEmailSms',
      title: formatMessage(venueSettingsMessages.tabGuestEmailSMS),
    },
    {
      key: 'reservations',
      title: formatMessage(venueSettingsMessages.tabReservations),
    },
    {
      key: 'charges',
      title: formatMessage(venueSettingsMessages.tabCharges),
    },
    ...(venueSettings?.ai_backwriter_enabled
      ? [
          {
            key: 'backwriter',
            title: formatMessage(venueSettingsMessages.tabBackwriter, {
              icon: <Icon name="VMSWeb-action-stars" size="sm" color="attentionNew" />,
            }),
          },
        ]
      : []),
  ]
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { query } = useDestination(routes.manager2.settings.venue)
  const activeTabIndex = tabs.findIndex(tab => tab.key === query.activeTab)
  const [selectedIndex, setSelectedIndex] = useState(activeTabIndex !== -1 ? activeTabIndex : 0)

  const schemaDining = useVenueSettingsDiningForm()
  const schemaNightlife = useVenueSettingsNightlifeForm()
  const schema = venueClass === VenueClassEnum.DINING ? schemaDining : schemaNightlife
  const {
    field,
    handleSubmit,
    formState: { isDirty, dirtyFields },
  } = useForm(schema, { defaultValues: fields })

  const onSubmit = handleSubmit(
    formData => onSave(cleanNullish(formData), dirtyFields),
    errors => {
      notify({ content: formatMessage(venueSettingsMessages.error), type: 'error' })
      // eslint-disable-next-line no-console
      console.error('errors', errors)
    }
  )

  const onTabChange = (index: number) => {
    setSelectedIndex(index)
    const tab = tabs[index]
    if (tab && venue) {
      nav.push(routes.manager2.settings.venue, { params: { venueKey: venue.urlKey }, query: { activeTab: tab.key } })
    }
  }

  return (
    <SettingsPageContent
      title={formatMessage(venueSettingsMessages.title)}
      description={formatMessage(venueSettingsMessages.description)}
      headerWidth="calc(100% - 274px)"
      actions={
        <Button variant="primary" disabled={!isDirty} onClick={onSubmit} data-test="button-save-changes">
          {formatMessage(commonMessages.saveChanges)}
        </Button>
      }
      tab={tabs[selectedIndex]?.title}
    >
      <Box p="lm" width="100%">
        <Tabs onSelect={onTabChange} selectedIndex={selectedIndex}>
          <TabList>
            {tabs.map(tab => (
              <Tab key={tab.key} data-test={`tab-${tab.key}`}>
                {tab.title}
              </Tab>
            ))}
          </TabList>
          <TabPanel>
            <InternalTeamEmails field={field.prop('internalTeamEmails')} options={options} venueClass={venueClass} />
          </TabPanel>
          <TabPanel>
            <GuestEmailSMS field={field.prop('guestEmailSMS')} options={options} venueClass={venueClass} />
          </TabPanel>
          <TabPanel>
            {venueClass === VenueClassEnum.DINING ? (
              <ReservationsDining field={field.prop('reservationsDining')} options={options} />
            ) : (
              <ReservationsNightlife field={field.prop('reservationsNightlife')} options={options} />
            )}
          </TabPanel>
          <TabPanel>
            {venueClass === VenueClassEnum.DINING ? (
              <ChargesDining field={field.prop('chargesDining')} />
            ) : (
              <ChargesNightlife field={field.prop('chargesNightlife')} />
            )}
          </TabPanel>
          {venueSettings?.ai_backwriter_enabled && (
            <TabPanel>
              <Backwriter field={field.prop('backwriter')} options={options} venueClass={venueClass} />
            </TabPanel>
          )}
        </Tabs>
      </Box>
      <TransitionBlockerWithOldRouterSupport modal={<UnsavedChangesModal />} skipQuery isBlocked={isDirty} />
    </SettingsPageContent>
  )
}
