import { useMemo } from 'react'
import type { POSIConfig, POSILocation } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import {
  convertAutomaticCheckCreationCardFormDataToPOSIConfig,
  getAutomaticCheckCreationCardFormDefaultValues,
  useAutomaticCheckCreationCardForm,
} from '../../AutomaticCheckCreationCard/AutomaticCheckCreationCardForm.zod'
import {
  convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig,
  getAutomaticPrepaymentRedemptionCardFormDefaultValues,
  useAutomaticPrepaymentRedemptionCardForm,
} from '../../AutomaticPrepaymentRedemptionCard/AutomaticPrepaymentRedemptionCardForm.zod'
import {
  getCourseStatusMappingCardFormDefaultValues,
  convertCourseStatusMappingCardFormDataToPOSIConfig,
  useCourseStatusMappingCardForm,
} from '../../CourseStatusMappingCard/CourseStatusMappingCardForm.zod'
import {
  getLocationSelectionCardFormDefaultValues,
  convertLocationSelectionCardFormDataToPOSIConfig,
  useLocationSelectionCardForm,
} from '../../LocationSelectionCard/LocationSelectionCardForm.zod'

export type EditPageFormData = ZodSchema<typeof useEditPageForm>
export type EditPageFormField = Field<EditPageFormData>

/**
 * This is currently only used by Square. However, we are keeping this around for now as a "common" edit page, as it
 * is likely that many POS systems will have identically structured edit pages.
 */
export function useEditPageForm() {
  const locationSelectionCard = useLocationSelectionCardForm()
  const automaticCheckCreationCard = useAutomaticCheckCreationCardForm()
  const automaticPrepaymentRedemptionCard = useAutomaticPrepaymentRedemptionCardForm()
  const courseStatusMappingCard = useCourseStatusMappingCardForm()

  return useMemo(
    () =>
      z.object({
        locationSelectionCard,
        automaticCheckCreationCard,
        automaticPrepaymentRedemptionCard,
        courseStatusMappingCard,
      }),
    [automaticCheckCreationCard, automaticPrepaymentRedemptionCard, courseStatusMappingCard, locationSelectionCard]
  )
}

export const getEditPageFormDefaultValues = (config: POSIConfig): EditPageFormData => ({
  locationSelectionCard: getLocationSelectionCardFormDefaultValues(config.location?.id),
  automaticCheckCreationCard: getAutomaticCheckCreationCardFormDefaultValues({
    isAutomaticCheckCreationEnabled: config.checkCreationEnabled,
  }),
  automaticPrepaymentRedemptionCard: getAutomaticPrepaymentRedemptionCardFormDefaultValues({
    isAutomaticPrepaymentRedemptionEnabled: config.depositsEnabled,
  }),
  courseStatusMappingCard: getCourseStatusMappingCardFormDefaultValues(config),
})

export const convertEditPageFormDataToPOSIConfig = ({
  config,
  locations,
  data,
}: {
  config: POSIConfig
  locations: POSILocation[]
  data: EditPageFormData
}): POSIConfig => ({
  lastSaved: config.lastSaved,
  ...convertCourseStatusMappingCardFormDataToPOSIConfig(data.courseStatusMappingCard),
  ...convertAutomaticCheckCreationCardFormDataToPOSIConfig({
    isAutomaticCheckCreationEnabled: data.automaticCheckCreationCard.isAutomaticCheckCreationEnabled,
  }),
  ...convertAutomaticPrepaymentRedemptionCardFormDataToPOSIConfig({
    isAutomaticPrepaymentRedemptionEnabled: data.automaticPrepaymentRedemptionCard.isAutomaticPrepaymentRedemptionEnabled,
  }),
  ...convertLocationSelectionCardFormDataToPOSIConfig({ locations, data: data.locationSelectionCard }),
  depositsEnabled: false,
  checkCreationEnabled: false,
})
