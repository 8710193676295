import React, { useEffect, useMemo, useState } from 'react'
import type { SimphonyLocation } from '@sevenrooms/core/domain'
import { useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormMultiSelect, FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { HStack } from '@sevenrooms/core/ui-kit/layout'
import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseLocationSelectionCard } from '../BaseLocationSelectionCard'
import { locationSelectOption } from '../LocationSelectionCard'
import { messages } from './SimphonyLocationSelectionCard.locales'
import type { SimphonyLocationSelectionCardFormField } from './SimphonyLocationSelectionCardForm.zod'

export interface SimphonyLocationSelectionCardProps {
  field: SimphonyLocationSelectionCardFormField
  locations: SimphonyLocation[]
}

export function SimphonyLocationSelectionCard({ field, locations }: SimphonyLocationSelectionCardProps) {
  const { formatMessage } = useLocales()

  // Clear checkPollingRvcs and checkCreationRvc if selectedLocationId changes
  const { watch, setValue } = useFormContext()
  const watchSelectedLocationId = watch('locationCard.locationId')
  const [selectedLocationId, setSelectedLocationId] = useState<string>(watchSelectedLocationId)
  useEffect(() => {
    if (watchSelectedLocationId !== selectedLocationId) {
      setValue('locationCard.checkPollingRvcs', [])
      setValue('automaticCheckCreationCard.checkCreationRvc', '')
      setValue('automaticPrepaymentRedemptionCard.depositMenuItemId', '')
      setSelectedLocationId(watchSelectedLocationId)
    }
  }, [watchSelectedLocationId, setValue, selectedLocationId])

  const { locationSelectOptions, revenueCenterSelectOptionsByLocationId } = useMemo(() => {
    const locationSelectOptions: SelectOption[] = []
    const revenueCenterSelectOptionsByLocationId: { [key: string]: SelectOption[] } = {}
    locations.forEach(location => {
      locationSelectOptions.push(locationSelectOption(location))
      revenueCenterSelectOptionsByLocationId[location.id] = location.revenueCenters.map(revenueCenter =>
        locationSelectOption(revenueCenter)
      )
    })
    return {
      locationSelectOptions,
      revenueCenterSelectOptionsByLocationId,
    }
  }, [locations])

  const revenueCenterSelectOptions = useMemo(
    () => revenueCenterSelectOptionsByLocationId[selectedLocationId] ?? [],
    [revenueCenterSelectOptionsByLocationId, selectedLocationId]
  )
  const isRevenueCenterSelectDisabled = useMemo(() => !selectedLocationId, [selectedLocationId])
  return (
    <BaseLocationSelectionCard docLink={posiMetaDataMap.SIMPHONY.docLink}>
      <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
        <Label width="50%" primary={formatMessage(messages.locationIdLabel)}>
          <FormSelect field={field.prop('locationId')} options={locationSelectOptions} hideErrorMessage />
        </Label>
        <Label width="50%" primary={formatMessage(messages.checkPollingRvcsLabel)}>
          <FormMultiSelect
            options={revenueCenterSelectOptions}
            field={field.prop('checkPollingRvcs')}
            disabled={isRevenueCenterSelectDisabled}
            hideErrorMessage
            placeholder={formatMessage(
              isRevenueCenterSelectDisabled ? messages.checkPollingRvcsDisabledPlaceholder : messages.checkPollingRvcsPlaceholder
            )}
            searchable
          />
        </Label>
      </HStack>
    </BaseLocationSelectionCard>
  )
}
