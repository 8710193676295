import type { SellingPoint } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Flex, HStack, Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { AccountTypes } from '@sevenrooms/payments'
import { IntegrationsMapping } from '../../Integrations'
import { ProcessorCircleLogoStripe, ProcessorCircleLogoGeneric } from '../../Integrations/ViewSettingsBlock'
import { StripeConnectButton } from '../StripeConnectButton'
import { messages } from './RecommendedIntegrationCard.locales'

export function RecommendedIntegrationCard({ integration }: { integration: SellingPoint }) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const integrationAttributes = IntegrationsMapping[integration.gateway]
  const { venueKey } = useVenueContext()

  if (!integrationAttributes) {
    return null
  }

  const onClick = () => {
    nav.push(routes.manager2.settings.paymentIntegration.edit, {
      params: { provider: integration.gateway, venueKey },
    })
  }

  return (
    <Box p="m" width="100%">
      <HStack spacing="m">
        <ProcessorCircleLogoGeneric processorLogo={integrationAttributes.logo} processorTitle={integrationAttributes.title} />
        <Flex flexWrap="wrap" flexGrow="1" pb="m" alignItems="end">
          <VStack spacing="s" alignItems="baseline">
            <HStack spacing="s" justifyContent="center" alignItems="end">
              <Text textStyle="h2">{integrationAttributes.title}</Text>
            </HStack>
            <Text textStyle="body1" color="secondaryFont">
              {integration.features.join(', ')}
            </Text>
          </VStack>
        </Flex>
        <Flex flexWrap="wrap" pb="m" alignItems="end">
          <HStack spacing="m">
            <Button
              variant="tertiary"
              noPadding
              onClick={() => window.open(integrationAttributes.doc)}
              data-test="payment-settings-provider-documentation-btn"
            >
              <Text textDecoration="underline" color="secondaryFont">
                {formatMessage(messages.learnMore)}
              </Text>
            </Button>
            <Button variant="primary" onClick={onClick} data-test="payment-settings-provider-setup-btn">
              {formatMessage(messages.setup)}
            </Button>
          </HStack>
        </Flex>
      </HStack>
    </Box>
  )
}

export function RecommendedIntegrationStripeCard() {
  const { formatMessage } = useLocales()
  const integrationAttributes = IntegrationsMapping[AccountTypes.STRIPE]
  if (!integrationAttributes) {
    return null
  }

  return (
    <Box p="l">
      <VStack spacing="m">
        <ProcessorCircleLogoStripe processorLogo={integrationAttributes.logo || ''} processorTitle={integrationAttributes.title} />
        <Flex flexWrap="wrap" pb="m">
          <VStack spacing="s">
            <HStack spacing="s" flexWrap="wrap">
              <Text textStyle="h2">{formatMessage(messages.stripe)}</Text>
              <Box>
                <StatusLabel variant="success" data-test="payment-settings-recommended-status-label">
                  {formatMessage(messages.recommendedIntegration)}
                </StatusLabel>
              </Box>
            </HStack>
            <Text textStyle="body1" color="secondaryFont">
              {formatMessage(messages.stripeDescription)}
            </Text>
          </VStack>
        </Flex>
        <Flex>
          <HStack spacing="m">
            <Flex>
              <StripeConnectButton />
            </Flex>
            <Button
              variant="tertiary"
              noPadding
              onClick={() => window.open(integrationAttributes.doc)}
              data-test="payment-settings-provider-documentation-btn"
            >
              <Text textDecoration="underline" color="secondaryFont">
                {formatMessage(messages.learnMore)}
              </Text>
            </Button>
          </HStack>
        </Flex>
      </VStack>
    </Box>
  )
}
