import { defineMessages } from '@sevenrooms/core/locales'

export const generalMessages = defineMessages({
  brandSettingsLabel: {
    id: 'brandSettingsLabel',
    defaultMessage: 'Brand Settings',
  },
  brandSettingsLogoUploaderLabel: {
    id: 'brandSettingsLogoUploaderLabel',
    defaultMessage: 'Header Logo',
  },
  brandSettingsLogoUploaderDescription: {
    id: 'brandSettingsLogoUploaderDescription',
    defaultMessage: 'Use a landscape-oriented version of your logo that’s legible at a small scale.',
  },
  brandSettingsHeaderUploaderLabel: {
    id: 'brandSettingsHeaderUploaderLabel',
    defaultMessage: 'Header Image',
  },
  brandSettingsHeaderUploaderDescription: {
    id: 'brandSettingsHeaderUploaderDescription',
    defaultMessage:
      'Use an image of your interior or food to show off the ambience of your venue. Recommendation: landscape image, at least 1500px wide and 750px tall.',
  },
  brandSettingsPrimaryColorLabel: {
    id: 'brandSettingsPrimaryColorLabel',
    defaultMessage: 'Primary Color',
  },
  brandSettingsPrimaryColorDescription: {
    id: 'brandSettingsPrimaryColorDescription',
    defaultMessage: 'Used for buttons and primary CTAs. Your boldest brand color is best.',
  },
  brandSettingsReservationHoldTimeMinsLabel: {
    id: 'brandSettingsReservationHoldTimeMinsLabel',
    defaultMessage: 'Timer to complete booking',
  },
  brandSettingReservationHoldTimeMinsDescription: {
    id: 'brandSettingReservationHoldTimeMinsDescription',
    defaultMessage: 'Minutes a time slot is on hold while the guest completes the reservation.{br}(Recommendation: 5 minutes)',
  },
  brandSettingsSecondaryColorLabel: {
    id: 'brandSettingsSecondaryColorLabel',
    defaultMessage: 'Secondary Color',
  },
  brandSettingsSecondaryColorDescription: {
    id: 'brandSettingsSecondaryColorDescription',
    defaultMessage: 'Used as a background color. Check that your logo looks good on top of it.',
  },
  brandSettingsVenueDescriptionFieldLabel: {
    id: 'brandSettingsVenueDescriptionFieldLabel',
    defaultMessage: 'Venue Description',
  },
  brandSettingsVenueDescriptionFieldDescription: {
    id: 'brandSettingsVenueDescriptionFieldDescription',
    defaultMessage:
      'Give your guest info about your venue and the experience you offer. 1-2 sentences (or approximately 400 characters) is enough.',
  },
  brandSettingsEditOtherLanguagesLinkLabel: {
    id: 'brandSettingsEditOtherLanguagesLinkLabel',
    defaultMessage: 'Edit other languages',
  },
  optionalMessagingLabel: {
    id: 'optionalMessagingLabel',
    defaultMessage: 'Optional Messaging',
  },
  widgetTitle: {
    id: 'widgetTitle',
    defaultMessage: 'Widget Title',
  },
  widgetTitleDescription: {
    id: 'widgetTitleDescription',
    defaultMessage: 'Short message you want your guests to know while finding their time slot.',
  },
  widgetPlacement: {
    id: 'widgetPlacement',
    defaultMessage: 'placement in Widget',
  },
  specialAttentionMessageTitle: {
    id: 'specialAttentionMessageTitle',
    defaultMessage: 'Special Attention Message',
  },
  specialAttentionMessageDescription: {
    id: 'specialAttentionMessageDescription',
    defaultMessage:
      'General information guests often want to know while booking, like cancellation policy or dress code. Supporting details appear in a pop-up.',
  },
  specialAttentionPlacement: {
    id: 'specialAttentionPlacement',
    defaultMessage: 'placements in Widget',
  },
  specialAttentionMainHeadlineLabel: {
    id: 'specialAttentionMainHeadlineLabel',
    defaultMessage: 'Special Attention Main Headline',
  },
  specialAttentionMainHeadlineDescription: {
    id: 'specialAttentionMainHeadlineDescription',
    defaultMessage: 'Keep it simple, approximately 100 characters.',
  },
  specialAttentionMainHeadlineErrorMessage: {
    id: 'specialAttentionMainHeadlineErrorMessage',
    defaultMessage: 'Add a Main Headline to make the Special Attention Message visible to guests.',
  },
  specialAttentionMainHeadlinePopupSetErrorMessage: {
    id: 'specialAttentionMainHeadlinePopupSetErrorMessage',
    defaultMessage: 'Add a Main Headline to make the Pop-up Content visible to guests.',
  },
  popupContentLabel: {
    id: 'popupContentLabel',
    defaultMessage: 'Special Attention Pop-up Content',
  },
  popupContentDescription: {
    id: 'popupContentDescription',
    defaultMessage: 'Add supporting details for guests who want to learn more. If left blank, there will be no pop-up.',
  },
  addSpecialAttentionMessageOptionalLabel: {
    id: 'addSpecialAttentionMessageOptionalLabel',
    defaultMessage: 'Add Special Attention Message to:',
  },
  enableSpecialAttentionSearchAndAvailabilityLabel: {
    id: 'enableSpecialAttentionSearchAndAvailabilityLabel',
    defaultMessage: 'Main Reservation Widget page (located above time slots)',
  },
  enableSpecialAttentionGuestBookingLabel: {
    id: 'enableSpecialAttentionGuestBookingLabel',
    defaultMessage:
      'Guest Booking Notifications (including Confirmation page, Reservation Management page, and Booking Notification emails)',
  },
  guestFacingLanguageLabel: {
    id: 'guestFacingLanguageLabel',
    defaultMessage: 'Guest-Facing Language',
  },
  guestFacingLanguageDescription: {
    id: 'guestFacingLanguageDescription',
    defaultMessage: 'Edit any other copy in {link}',
  },
  guestFacingLanguagePlacement: {
    id: 'guestFacingLanguagePlacement',
    defaultMessage: 'Reservation Widget Guest-Facing Language',
  },
  optionalBannersLabel: {
    id: 'optionalBannersLabel',
    defaultMessage: 'Optional Banners',
  },
  privateEventsBannerCheckboxTitle: {
    id: 'privateEventsBannerCheckboxTitle',
    defaultMessage: 'Group Bookings banner',
  },
  privateEventsBannerCheckboxDescription: {
    id: 'privateEventsBannerCheckboxDescription',
    defaultMessage:
      'Let guests know they can book private events and large parties. This banner will link guests to your Group Bookings tab.',
  },
  privateEventsBannerImageTitle: {
    id: 'privateEventsBannerImageTitle',
    defaultMessage: 'Banner Image',
  },
  privateEventsBannerImageDescription: {
    id: 'privateEventsBannerImageDescription',
    defaultMessage:
      'Use an image of your most impressive private or semi-private space. Recommendation: landscape image, at least 600px wide and 360px tall.',
  },
  privateEventsBannerHeadlineTitle: {
    id: 'privateEventsBannerHeadlineTitle',
    defaultMessage: 'Banner Headline',
  },
  privateEventsBannerHeadlineDescription: {
    id: 'privateEventsBannerHeadlineDescription',
    defaultMessage: 'Keep it simple, approximately 40 characters.',
  },
  privateEventsBannerCopyTitle: {
    id: 'privateEventsBannerCopyTitle',
    defaultMessage: 'Banner Copy',
  },
  privateEventsBannerCopyDescription: {
    id: 'privateEventsBannerCopyDescription',
    defaultMessage: 'Keep it short, approximately 60 characters.',
  },
  privateEventsBannerButtonLabelTitle: {
    id: 'privateEventsBannerButtonLabelTitle',
    defaultMessage: 'Button Label',
  },
  privateEventsBannerButtonLabelDescription: {
    id: 'privateEventsBannerButtonLabelDescription',
    defaultMessage: 'Be succinct, no more than 35 characters.',
  },
  legacyReservationWidgetLabel: {
    id: 'legacyReservationWidgetLabel',
    defaultMessage: 'Legacy Reservation Widget',
  },
  legacyReservationWidgetDesc: {
    id: 'legacyReservationWidgetDesc',
    defaultMessage:
      'If you revert back to the legacy widget, all links to your new widget will instantly and automatically be replaced with your legacy widget URL.',
  },
  legacyReservationWidgetAction: {
    id: 'legacyReservationWidgetAction',
    defaultMessage: 'Revert to Legacy Widget',
  },
  legacyReservationWidgetRevertConfirmationHeader: {
    id: 'legacyReservationWidgetRevertConfirmationHeader',
    defaultMessage: 'Are you sure you want to revert to the legacy widget?',
  },
  legacyReservationWidgetRevertConfirmationAction: {
    id: 'legacyReservationWidgetRevertConfirmationAction',
    defaultMessage: 'Yes, Revert',
  },
  legacyReservationWidgetRevertConfirmationRejection: {
    id: 'legacyReservationWidgetRevertConfirmationRejection',
    defaultMessage: 'No, Go Back',
  },
  legacyReservationWidgetRevertError: {
    id: 'legacyReservationWidgetRevertError',
    defaultMessage: 'An error occurred while trying to revert to the legacy Widget. Please try again.',
  },
})
