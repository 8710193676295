import { useMemo } from 'react'
import { AdditionalInfoEnum, CostOptionsEnum } from '@sevenrooms/core/domain'
import { type Field, useWatch } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { CurrencyInput, getNumberOptions } from '@sevenrooms/core/ui-kit/core'
import { FormSelect, type CheckboxChoice, CheckboxGroup, FormInput, Label, Radio, TextArea, FormToggle } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, RelatedSettings, SettingsSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { SectionBox } from '../components/SectionBox'
import { venueSettingsMessages } from '../VenueSettings.locales'
import { chargesMessages } from './Charges.locales'
import type { ChargesNightlifeForm, CostOptionsValues } from './ChargesNightlife.zod'

export interface ChargesNightlifeProps {
  field: Field<ChargesNightlifeForm>
}

const percentOptions = getNumberOptions({ max: 39, suffix: '%' })

export function ChargesNightlife({ field }: ChargesNightlifeProps) {
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  const costOptionsChoices = useMemo(
    (): Array<CheckboxChoice<CostOptionsValues>> => [
      { value: CostOptionsEnum.MINIMUM_DOLLARS, label: formatMessage(chargesMessages.costOptionsMinDollars) },
      { value: CostOptionsEnum.MINIMUM_BOTTLES, label: formatMessage(chargesMessages.costOptionsMinBottles) },
      { value: CostOptionsEnum.NO_MINIMUM, label: formatMessage(chargesMessages.costOptionsNoMin) },
      { value: CostOptionsEnum.COMPED, label: formatMessage(chargesMessages.costOptionsComped) },
      { value: CostOptionsEnum.TOTAL_DOLLARS, label: formatMessage(chargesMessages.costOptionsTotalDollars) },
    ],
    [formatMessage]
  )

  const printGratuityEnabledValue = useWatch(field.prop('printGratuityEnabled'))
  const avgBottlePriceValue = useWatch(field.prop('avgBottlePrice'))

  return (
    <VStack spacing="lm" mt="lm">
      <SectionBox title={formatMessage(chargesMessages.sectionReservationCharges)}>
        <HStack spacing="lm" alignItems="flex-start">
          <VStack spacing="l">
            <Label
              primary={formatMessage(chargesMessages.serviceChargeTitle)}
              secondary={formatMessage(chargesMessages.serviceChargeDescription)}
            >
              <HStack>
                <FormSelect
                  options={percentOptions}
                  searchable={false}
                  hideErrorMessage
                  field={field.prop('serviceCharge')}
                  data-test="serviceCharge"
                />
              </HStack>
            </Label>
            <Label primary={formatMessage(chargesMessages.gratuityTitle)} secondary={formatMessage(chargesMessages.gratuityDescription)}>
              <HStack>
                <FormSelect
                  options={percentOptions}
                  searchable={false}
                  hideErrorMessage
                  field={field.prop('gratuity')}
                  data-test="gratuity"
                />
              </HStack>
            </Label>
            <FormToggle
              label={formatMessage(chargesMessages.trackCompsTitle)}
              description={formatMessage(chargesMessages.trackCompsDescription)}
              field={field.prop('trackComps')}
              data-test="trackComps"
            />
            <VStack spacing="s" mb="l">
              <Label
                primary={formatMessage(chargesMessages.costOptionsTitle)}
                secondary={formatMessage(chargesMessages.costOptionsDescription)}
              />
              <CheckboxGroup field={field.prop('costOptions')} data-test="costOptions" choices={costOptionsChoices} />
            </VStack>
            <Label
              primary={formatMessage(chargesMessages.printChitCommitmentMsgTitle)}
              secondary={formatMessage(chargesMessages.printChitCommitmentMsgDescription)}
            >
              <TextArea
                placeholder={formatMessage(chargesMessages.printChitCommitmentMsgPlaceholder)}
                field={field.prop('printChitCommitmentMsg')}
                data-test="printChitCommitmentMsg"
              />
            </Label>
            <VStack spacing="sm">
              <Label
                primary={formatMessage(chargesMessages.printChitEnabledTitle)}
                secondary={formatMessage(chargesMessages.printChitEnabledDescription)}
              />
              <Radio
                field={field.prop('printGratuityEnabled')}
                data-test="printGratuityEnabled-YES"
                value={AdditionalInfoEnum.YES}
                checked={printGratuityEnabledValue === AdditionalInfoEnum.YES}
              >
                {formatMessage(commonMessages.yes)}
              </Radio>
              {printGratuityEnabledValue === AdditionalInfoEnum.YES && (
                <VStack spacing="sm" pl="lm">
                  <Label primary={formatMessage(chargesMessages.printGratuityCommitmentMsgTitle)}>
                    <TextArea
                      placeholder={formatMessage(chargesMessages.printGratuityCommitmentMsgPlaceholder)}
                      field={field.prop('printGratuityCommitmentMsg')}
                      data-test="printGratuityCommitmentMsg"
                    />
                  </Label>
                  <Label primary={formatMessage(chargesMessages.printGratuityFirstOptTitle)}>
                    <FormInput field={field.prop('printGratuityFirstOpt')} data-test="printGratuityFirstOpt" />
                  </Label>
                  <Label primary={formatMessage(chargesMessages.printGratuitySecondOptTitle)}>
                    <FormInput field={field.prop('printGratuitySecondOpt')} data-test="printGratuitySecondOpt" />
                  </Label>
                  <Label primary={formatMessage(chargesMessages.printGratuityChangeMsgTitle)}>
                    <TextArea
                      placeholder={formatMessage(chargesMessages.printGratuityChangeMsgPlaceholder)}
                      field={field.prop('printGratuityChangeMsg')}
                      data-test="printGratuityChangeMsg"
                    />
                  </Label>
                </VStack>
              )}
              <Radio
                field={field.prop('printGratuityEnabled')}
                data-test="printGratuityEnabled-NO"
                value={AdditionalInfoEnum.NO}
                checked={printGratuityEnabledValue === AdditionalInfoEnum.NO}
              >
                {formatMessage(commonMessages.no)}
              </Radio>
            </VStack>
            <SettingsSection
              collapsible
              defaultCollapsed
              size="s"
              memoCollapseState="generalSettingsChargesNightlife"
              title={formatMessage(venueSettingsMessages.additionalConfig)}
              data-test="chargesNightlifeSection"
            >
              <Box pt="m">
                <Label
                  primary={formatMessage(chargesMessages.avgBottlePriceTitle)}
                  secondary={formatMessage(chargesMessages.avgBottlePriceDescription)}
                >
                  <HStack>
                    <CurrencyInput
                      value={avgBottlePriceValue}
                      onChange={(value: number | null) => field.prop('avgBottlePrice').set(value ?? 1)}
                      data-test="avgBottlePrice"
                      greaterThanZero
                      decimalScale={0}
                    />
                  </HStack>
                </Label>
              </Box>
            </SettingsSection>
          </VStack>
          <RelatedSettings
            size="s"
            primary={formatMessage(chargesMessages.taxRatesTitle)}
            secondary={formatMessage(chargesMessages.taxRatesDescription)}
            label={formatMessage(chargesMessages.taxRatesButton)}
            onClick={() => {
              if (venue) {
                window.location.replace(nav.href(routes.manager.manage.taxRates, { params: { venueKey: venue.urlKey } }))
              }
            }}
          />
        </HStack>
      </SectionBox>
    </VStack>
  )
}
