import React, { useMemo } from 'react'
import {
  type POSICheckTriggers,
  type POSICourseStatus,
  POSITypeEnum,
  type SquareReservationIntegrationStatus,
  SquareReservationIntegrationStatusEnum,
  type SquareLocation,
  type SquareConfig,
} from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { CourseStatusMappingCard } from '../../../CourseStatusMappingCard'
import { SquareLocationSelectionCard } from '../../../LocationSelectionCard'
import { BaseEditPageForm } from '../BaseEditPageForm'
import {
  type EditPageFormData,
  getEditPageFormDefaultValues,
  convertEditPageFormDataToPOSIConfig,
  useEditPageForm,
} from '../EditPageForm.zod'
import { useSavePOSIConfig } from '../hooks'
import type { EditPageFormProps } from '../EditPageForm'

export interface SquareEditPageFormProps extends EditPageFormProps {
  locations: SquareLocation[]
  config: SquareConfig
  inServiceStatuses: POSICourseStatus[]
  checkTriggers?: POSICheckTriggers
  refetchConfig: () => void
  isFetching: boolean
  courseStatusMappingErrorBanners?: React.ReactNode
}
export function SquareEditPageForm({
  locations,
  config,
  inServiceStatuses,
  checkTriggers,
  refetchConfig,
  isFetching,
  courseStatusMappingErrorBanners,
}: SquareEditPageFormProps) {
  const { saveConfig, isSaving } = useSavePOSIConfig()

  const defaultValues = useMemo(() => getEditPageFormDefaultValues(config), [config])
  const form = useForm(useEditPageForm(), {
    defaultValues,
  })
  const { field, formState } = form
  const { isDirty } = formState

  const disabled = useMemo(() => !isDirty || isSaving || isFetching, [isDirty, isSaving, isFetching])

  const reservationIntegrationStatus: SquareReservationIntegrationStatus | undefined = config.location?.reservationIntegration.status
  const isEligibleLocation: boolean =
    !reservationIntegrationStatus || reservationIntegrationStatus === SquareReservationIntegrationStatusEnum.ACTIVE

  const handleSave = async (data: EditPageFormData) => {
    await saveConfig({
      posiType: POSITypeEnum.SQUARE,
      config: convertEditPageFormDataToPOSIConfig({ config, locations, data }),
      refetchConfig,
    })
  }

  const isLocation = !!config.location

  return (
    <Form {...form} onSubmit={data => handleSave(data)} onInvalid={() => {}}>
      <BaseEditPageForm posiType={POSITypeEnum.SQUARE} disabled={disabled}>
        <SquareLocationSelectionCard
          field={field.prop('locationSelectionCard')}
          disabled={isLocation}
          locations={locations}
          isEligibleLocation={isEligibleLocation}
        />
        {isEligibleLocation && (
          <CourseStatusMappingCard
            isFetching={isFetching}
            field={field.prop('courseStatusMappingCard')}
            inServiceStatuses={inServiceStatuses}
            checkTriggers={checkTriggers}
            posiType={POSITypeEnum.SQUARE}
            errorBanners={courseStatusMappingErrorBanners}
            isLocation={isLocation}
          />
        )}
      </BaseEditPageForm>
    </Form>
  )
}
