import { useEffect, useMemo, useState } from 'react'
import { type SimphonyLocation, SimphonyTenderItemTypeEnum } from '@sevenrooms/core/domain'
import { useFormContext } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { FormNumberInput, FormSelect, Label } from '@sevenrooms/core/ui-kit/form'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { BaseAutomaticCheckCreationCard } from '../BaseAutomaticCheckCreationCard'
import { messages } from './SimphonyAutomaticCheckCreationCard.locales'
import type { SimphonyAutomaticCheckCreationCardFormField } from './SimphonyAutomaticCheckCreationCardForm.zod'

export interface SimphonyAutomaticCheckCreationCardProps {
  field: SimphonyAutomaticCheckCreationCardFormField
  locations: SimphonyLocation[]
}

export function SimphonyAutomaticCheckCreationCard({ field, locations }: SimphonyAutomaticCheckCreationCardProps) {
  const { formatMessage } = useLocales()
  const { watch, setValue } = useFormContext()
  const watchIsAutomaticCheckCreationEnabled = watch('automaticCheckCreationCard.isAutomaticCheckCreationEnabled')
  const watchCheckCreationRvc = watch('automaticCheckCreationCard.checkCreationRvc')
  const [selectedCheckCreationRvc, setSelectedCheckCreationRvc] = useState<string>(watchCheckCreationRvc)
  const selectedLocationId = watch('locationCard.locationId')

  // Field disabled conditions
  const isCheckCreationRvcDisabled = useMemo(
    () => locations.length === 0 || !watchIsAutomaticCheckCreationEnabled,
    [locations.length, watchIsAutomaticCheckCreationEnabled]
  )
  const isOrderTypeRefIdAndTenderIdDisabled = useMemo(
    () => isCheckCreationRvcDisabled || !watchCheckCreationRvc,
    [isCheckCreationRvcDisabled, watchCheckCreationRvc]
  )
  const isEmployeeRefIdDisabled = !watchIsAutomaticCheckCreationEnabled

  useEffect(() => {
    if (watchCheckCreationRvc !== selectedCheckCreationRvc) {
      setValue('automaticCheckCreationCard.orderTypeRefId', '')
      setValue('automaticCheckCreationCard.tenderId', '')
      setSelectedCheckCreationRvc(watchCheckCreationRvc)
    }
  }, [watchCheckCreationRvc, setValue, selectedCheckCreationRvc])

  const { checkCreationRvcSelectOptions, orderTypeRefIdSelectOptions, tenderIdSelectOptions } = useMemo(() => {
    const selectedLocation = locations.find(location => location.id === selectedLocationId)
    const revenueCenters = selectedLocation?.revenueCenters ?? []
    const checkCreationRvcSelectOptions: SelectOption[] = []
    const orderTypeRefIdSelectOptions: SelectOption[] = []
    const tenderIdSelectOptions: SelectOption[] = []
    revenueCenters.forEach(revenueCenter => {
      checkCreationRvcSelectOptions.push({ id: revenueCenter.id, label: revenueCenter.name })
      if (revenueCenter.id === watchCheckCreationRvc) {
        orderTypeRefIdSelectOptions.push(
          ...revenueCenter.orderTypes.map(orderType => ({
            id: orderType.orderTypeRef,
            label: `${orderType.name} (${orderType.orderTypeRef})`,
          }))
        )
        tenderIdSelectOptions.push(
          ...revenueCenter.tenderItems
            .filter(tenderItem => tenderItem.type === SimphonyTenderItemTypeEnum.SERVICE_TOTAL)
            .map(tenderItem => ({ id: tenderItem.tenderId, label: `${tenderItem.name} (${tenderItem.tenderId})` }))
        )
      }
    })
    return { checkCreationRvcSelectOptions, orderTypeRefIdSelectOptions, tenderIdSelectOptions }
  }, [locations, watchCheckCreationRvc, selectedLocationId])

  return (
    <BaseAutomaticCheckCreationCard
      docLink="https://help.sevenrooms.com/hc/en-us/articles/19062449880347-Oracle-MICROS-Simphony-Cloud-v19-2-or-newer-Configuring-Automatic-Check-Creation-and-Check-Tag-Display"
      isAutomaticCheckCreationEnabledField={field.prop('isAutomaticCheckCreationEnabled')}
    >
      <VStack width="100%" spacing="s" justifyContent="center" alignItems="start">
        <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
          <Label width="50%" primary={formatMessage(messages.checkCreationRvcLabel)}>
            <FormSelect
              field={field.prop('checkCreationRvc')}
              disabled={isCheckCreationRvcDisabled}
              options={checkCreationRvcSelectOptions}
              hideErrorMessage
            />
          </Label>
          <Label width="50%" primary={formatMessage(messages.orderTypeRefIdLabel)}>
            <FormSelect
              field={field.prop('orderTypeRefId')}
              disabled={isOrderTypeRefIdAndTenderIdDisabled}
              options={orderTypeRefIdSelectOptions}
              hideErrorMessage
            />
          </Label>
        </HStack>
        <HStack width="100%" spacing="m" justifyContent="center" alignItems="center">
          <Label width="50%" primary={formatMessage(messages.tenderIdLabel)}>
            <FormSelect
              field={field.prop('tenderId')}
              disabled={isOrderTypeRefIdAndTenderIdDisabled}
              options={tenderIdSelectOptions}
              hideErrorMessage
            />
          </Label>
          <Label width="50%" primary={formatMessage(messages.employeeRefIdLabel)}>
            <FormNumberInput field={field.prop('employeeRefId')} disabled={isEmployeeRefIdDisabled} fullWidth hideErrorMessage />
          </Label>
        </HStack>
      </VStack>
    </BaseAutomaticCheckCreationCard>
  )
}
