import { posiMetaDataMap } from '../../../ReferenceData'
import { BaseAutomaticCheckCreationCard } from '../BaseAutomaticCheckCreationCard'
import type { AutomaticCheckCreationCardFormField } from '../AutomaticCheckCreationCardForm.zod'

export interface SquareAutomaticCheckCreationCardProps {
  field: AutomaticCheckCreationCardFormField
}

export function SquareAutomaticCheckCreationCard({ field }: SquareAutomaticCheckCreationCardProps) {
  return (
    <BaseAutomaticCheckCreationCard
      docLink={posiMetaDataMap.SQUARE.docLink}
      isAutomaticCheckCreationEnabledField={field.prop('isAutomaticCheckCreationEnabled')}
    />
  )
}
