import { useCallback, useMemo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { generatePath } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Image, SettingsBanner, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import upgradesImage from './upgrades.svg'
import { upgradesBannerMessages } from './UpgradesBanner.locales'

const contentHeight = '154px'

export function UpgradesBanner() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const linkToUpgradesPage = useMemo(() => generatePath(routes.manager.upgrades.path, { venueKey: venue.urlKey }), [venue.urlKey])
  const onManageUpgradesClick = useCallback(() => {
    window.open(linkToUpgradesPage, '_blank')
  }, [linkToUpgradesPage])

  return (
    <SettingsBanner p="l" borderRadius="s" flexDirection="row" justifyContent="space-between" data-test="manage-upgrades-banner">
      <VStack justifyContent="space-between" minHeight={contentHeight}>
        <VStack spacing="s">
          <Text textStyle="h1">{formatMessage(upgradesBannerMessages.title)}</Text>
          <Box width="70%">
            <Text textStyle="body1">{formatMessage(upgradesBannerMessages.description)}</Text>
          </Box>
        </VStack>
        <Box>
          <Button variant="primary" data-test="manage-upgrades-button" onClick={onManageUpgradesClick}>
            {formatMessage(upgradesBannerMessages.manageUpgradesButton)}&nbsp;
            <Icon name="VMSWeb-open-in-new" size="lg" />
          </Button>
        </Box>
      </VStack>
      <Image height={contentHeight} src={upgradesImage} alt="upgrades" data-test="manage-upgrades-image" />
    </SettingsBanner>
  )
}
