import _ from 'lodash'
import { useMemo } from 'react'
import { type Field, useWatchMany } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Input } from '@sevenrooms/core/ui-kit/core'
import {
  Checkbox,
  ColorPicker,
  FormInput,
  FormToggle,
  ImageUploader,
  Label,
  RadioGroup,
  RadioGroupToggle,
  type RadioToggleChoice,
  SwitchSet,
  TextArea,
  ErrorMessage,
} from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { ButtonPicker } from './ButtonPicker'
import { subscriptionLocales } from './SubscriptionLocales'
import type { SubscriptionFormSchema } from './SubscriptionFormSchema.zod'

interface SubscriptionFormProps {
  field: Field<SubscriptionFormSchema>
  showHeaderImageIsResWidgetImage?: boolean
}

export function SubscriptionForm({ field, showHeaderImageIsResWidgetImage }: SubscriptionFormProps) {
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  const radioChoices = [
    { label: formatMessage(subscriptionLocales.radioChoiceLabelHidden), value: 'hidden' },
    { label: formatMessage(subscriptionLocales.radioChoiceLabelRequired), value: 'required' },
    { label: formatMessage(subscriptionLocales.radioChoiceLabelOptional), value: 'optional' },
  ] as const

  const [
    buttonColor,
    buttonId,
    customButtonText,
    headerImageIsResWidgetImage,
    logoIsVenueLogo,
    showHeaderImage,
    textButton,
    showVenueEmailOptIn,
    showVenueGroupEmailOptIn,
    showVenueSMSOptIn,
  ] = useWatchMany(field, [
    'buttonColor',
    'buttonId',
    'customButtonText',
    'headerImageIsResWidgetImage',
    'logoIsVenueLogo',
    'showHeaderImage',
    'textButton',
    'showOptInMarketingVenuePolicy',
    'showOptInMarketingVenueGroupPolicy',
    'showOptInSmsMarketingVenuePolicy',
  ])

  const htmlEmbed = useMemo(
    () =>
      [
        `<div id="sr-subscription-root" data-button-id="${buttonId}" data-color="${buttonColor}">${customButtonText}</div>`,
        `<script>SEVENROOMS_VENUE_ID = "${venue.urlKey}"</script>`,
        `<script src="https://${window.location.host}/widget/embed.js"></script>`,
        `<script src="https://use.fortawesome.com/cb6d04c8.js"></script>`,
      ].join('\n'),
    [buttonColor, buttonId, customButtonText, venue.urlKey]
  )

  // hide the option to select res widget header image if it doesn't exist
  const headerImageIsResWidgetImageChoices: RadioToggleChoice[] = [
    { label: formatMessage(subscriptionLocales.headerImageRadioOptionUploadNew), value: false },
  ]
  if (showHeaderImageIsResWidgetImage) {
    const widgetSettingsRoute =
      venueSettings?.new_res_widget_enabled && venueSettings?.force_redirects_on_legacy_widget
        ? routes.manager2.settings.widgetSettings.reservationWidgetSettingsV2
        : routes.manager.settings.widgets.reservationWidgetSettings

    headerImageIsResWidgetImageChoices.unshift({
      label: formatMessage(subscriptionLocales.headerImageRadioOptionExisting, {
        link: (
          <Anchor
            target=""
            href={nav.href(widgetSettingsRoute, {
              params: { venueKey: venue.urlKey },
            })}
          >
            {formatMessage(subscriptionLocales.headerImageRadioOptionExistingLink)}
          </Anchor>
        ),
      }),
      value: true,
    })
  }

  return (
    <VStack pl="lm" pb="lm" spacing="lm">
      <BaseSection title={formatMessage(subscriptionLocales.formattingSectionTitle)}>
        <VStack p="lm" spacing="lm">
          <Box>
            <Label primary={formatMessage(subscriptionLocales.colorPrimary)} />
            <ColorPicker field={field.prop('colorPrimary')} />
          </Box>

          <Box>
            <Label primary={formatMessage(subscriptionLocales.logoHeader)}>
              <RadioGroupToggle
                field={field.prop('logoIsVenueLogo')}
                choices={[
                  {
                    label: formatMessage(subscriptionLocales.logoHeaderRadioOptionExisting, {
                      link: (
                        <Anchor
                          target=""
                          href={nav.href(routes.manager2.settings.venue, {
                            params: { venueKey: venue.urlKey },
                            query: { activeTab: 'guestEmailSms' },
                          })}
                        >
                          {formatMessage(subscriptionLocales.logoHeaderRadioOptionExistingLink)}
                        </Anchor>
                      ),
                    }),
                    value: true,
                  },
                  { label: formatMessage(subscriptionLocales.logoHeaderRadioOptionUploadNew), value: false },
                ]}
              />
            </Label>
            {!logoIsVenueLogo && (
              <Box>
                <ImageUploader field={field.prop('logo')} data-test="logo" />
              </Box>
            )}
          </Box>

          <Box>
            <FormToggle label={formatMessage(subscriptionLocales.showHeaderImageLabel)} field={field.prop('showHeaderImage')} />
            {showHeaderImage && (
              <Box pt="s">
                <RadioGroupToggle field={field.prop('headerImageIsResWidgetImage')} choices={headerImageIsResWidgetImageChoices} />
                {!headerImageIsResWidgetImage && (
                  <Box>
                    <ImageUploader field={field.prop('headerImage')} data-test="headerImage" />
                  </Box>
                )}
              </Box>
            )}
          </Box>
        </VStack>
      </BaseSection>

      <BaseSection title={formatMessage(subscriptionLocales.emailSignupButtonSettingsTitle)}>
        <VStack p="lm" spacing="lm">
          <Label primary={formatMessage(subscriptionLocales.customButtonText)}>
            <FormInput field={field.prop('customButtonText')} />
          </Label>

          <Box>
            <Label primary={formatMessage(subscriptionLocales.characters)} />
            <SwitchSet
              name="characters"
              choices={
                [
                  { label: formatMessage(subscriptionLocales.charactersAllUpperCaseLabel), value: 'AA' },
                  { label: formatMessage(subscriptionLocales.charactersCapitalizedLabel), value: 'Aa' },
                ] as const
              }
              selected={textButton}
              onChange={({ value }) => {
                field.prop('textButton').set(value)
                field.prop('customButtonText').set(value === 'AA' ? customButtonText.toUpperCase() : _.capitalize(customButtonText))
              }}
            />
          </Box>

          <Box>
            <Label primary={formatMessage(subscriptionLocales.buttonColor)} />
            <ColorPicker field={field.prop('buttonColor')} />
          </Box>

          <ButtonPicker
            color={buttonColor}
            text={customButtonText}
            field={field.prop('buttonId')}
            label={formatMessage(subscriptionLocales.buttonPickerLabel)}
          />

          <Text fontStyle="italic" color="secondaryFont">
            {formatMessage(subscriptionLocales.htmlEmbedMessageLine1)}
            <br />
            {formatMessage(subscriptionLocales.htmlEmbedMessageLine2)}
          </Text>

          <Label
            primary={formatMessage(subscriptionLocales.htmlEmbedLabel)}
            secondary={formatMessage(subscriptionLocales.htmlEmbedInstructions)}
          >
            <TextArea value={htmlEmbed} disabled />
          </Label>

          <Label primary={formatMessage(subscriptionLocales.directLinkLabel)}>
            <Input
              value={`https://${window.location.host + nav.href(routes.explore.subscription, { params: { venueKey: venue.urlKey } })}`}
              disabled
            />
          </Label>
          <Label primary={formatMessage(subscriptionLocales.redirectUrl)}>
            <FormInput field={field.prop('redirectUrl') as Field<string>} />
          </Label>
          <FormToggle
            label={formatMessage(subscriptionLocales.displayLinkToTheSubscriptionWidgetOnEmailConfirmationsLabel)}
            field={field.prop('displayLinkToTheSubscriptionWidgetOnEmailConfirmations')}
          />
        </VStack>
      </BaseSection>

      <BaseSection
        title={formatMessage(subscriptionLocales.signUpConfigurationTitle)}
        description={formatMessage(subscriptionLocales.signUpConfigurationReminder)}
      >
        <VStack p="lm" spacing="lm">
          <Label primary={formatMessage(subscriptionLocales.salutationInputLabel)}>
            <RadioGroup field={field.prop('salutation')} choices={radioChoices} />
          </Label>

          <Label primary={formatMessage(subscriptionLocales.birthdayInputLabel)}>
            <RadioGroup field={field.prop('birthday')} choices={radioChoices} />
          </Label>

          <Label primary={formatMessage(subscriptionLocales.postalCodeInputLabel)}>
            <RadioGroup field={field.prop('postalCode')} choices={radioChoices} />
          </Label>

          <Label primary={formatMessage(subscriptionLocales.dietaryRestrictionsInputLabel)}>
            <RadioGroup
              field={field.prop('dietaryRestrictions')}
              choices={
                [
                  { label: formatMessage(subscriptionLocales.radioChoiceLabelHidden), value: 'hidden' },
                  { label: formatMessage(subscriptionLocales.radioChoiceLabelOptional), value: 'optional' },
                ] as const
              }
            />
          </Label>

          {venueSettings?.is_sms_marketing_enabled && (
            <VStack spacing="s">
              <FormToggle
                label={formatMessage(subscriptionLocales.showOptInMarketingSMSPolicyToggleLabel)}
                field={field.prop('showOptInSmsMarketingVenuePolicy')}
                hideErrorMessage
              />
              {showVenueSMSOptIn && (
                <Checkbox
                  field={field.prop('showOptInSmsMarketingVenuePolicyDefault')}
                  info={
                    <>
                      {formatMessage(subscriptionLocales.showOptInMarketingSMSDefaultInfoLine1)}
                      <br />
                      <br />
                      {formatMessage(subscriptionLocales.showOptInMarketingDisclaimer)}
                    </>
                  }
                >
                  {formatMessage(subscriptionLocales.showOptInMarketingDefaultLabel)}
                </Checkbox>
              )}
            </VStack>
          )}

          {venueSettings?.batch_emails_enabled && (
            <VStack spacing="s">
              <FormToggle
                label={formatMessage(subscriptionLocales.showOptInMarketingVenuePolicyToggleLabel)}
                field={field.prop('showOptInMarketingVenuePolicy')}
                onChange={newVenuePolicy => {
                  if (!newVenuePolicy) {
                    field.prop('showOptInMarketingVenueGroupPolicy').set(false)
                  }
                }}
                hideErrorMessage
              />
              {showVenueEmailOptIn && (
                <Checkbox
                  field={field.prop('showOptInMarketingVenuePolicyDefault')}
                  info={
                    <>
                      {formatMessage(subscriptionLocales.showOptInMarketingVenuePolicyDefaultInfoLine1)}
                      <br />
                      <br />
                      {formatMessage(subscriptionLocales.showOptInMarketingDisclaimer)}
                    </>
                  }
                  onChange={ev => {
                    const { checked } = ev.target
                    if (!checked) {
                      field.prop('showOptInMarketingVenueGroupPolicyDefault').set(false)
                    }
                  }}
                >
                  {formatMessage(subscriptionLocales.showOptInMarketingDefaultLabel)}
                </Checkbox>
              )}
            </VStack>
          )}

          {venueSettings?.batch_emails_enabled && (
            <VStack spacing="s">
              <FormToggle
                label={formatMessage(subscriptionLocales.showOptInMarketingVenueGroupPolicyToggleLabel)}
                field={field.prop('showOptInMarketingVenueGroupPolicy')}
                onChange={newVenueGroupPolicy => {
                  if (newVenueGroupPolicy) {
                    field.prop('showOptInMarketingVenuePolicy').set(newVenueGroupPolicy)
                  }
                }}
                hideErrorMessage
              />
              {showVenueGroupEmailOptIn && (
                <Checkbox
                  field={field.prop('showOptInMarketingVenueGroupPolicyDefault')}
                  info={
                    <>
                      {formatMessage(subscriptionLocales.showOptInMarketingVenueGroupPolicyDefaultInfoLine1)}
                      <br />
                      <br />
                      {formatMessage(subscriptionLocales.showOptInMarketingDisclaimer)}
                    </>
                  }
                  onChange={ev => {
                    const { checked } = ev.target
                    if (checked) {
                      field.prop('showOptInMarketingVenuePolicyDefault').set(true)
                    }
                  }}
                >
                  {formatMessage(subscriptionLocales.showOptInMarketingDefaultLabel)}
                </Checkbox>
              )}
            </VStack>
          )}

          {!showVenueEmailOptIn && !showVenueGroupEmailOptIn && !showVenueSMSOptIn && (
            <ErrorMessage>{formatMessage(subscriptionLocales.noMarketingOptInSelected)}</ErrorMessage>
          )}

          <FormToggle label={formatMessage(subscriptionLocales.enableRecaptchaLabel)} field={field.prop('recaptchaOn')} />
        </VStack>
      </BaseSection>
    </VStack>
  )
}
