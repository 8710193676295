import * as React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, HStack, PreviewImage, VStack } from '@sevenrooms/core/ui-kit/layout'
import { colors } from '@sevenrooms/core/ui-kit/theme'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { Link } from '@sevenrooms/react-components/components/Link'
import { customerSupportLink } from '../../ReferenceData'
import { messages } from './POSICard.locales'

export interface POSICardData {
  logo: string
  cardSummary: string
  title: string
  docLink: string
  // This is the url to take the user to somewhere they can begin the authentication process.
  // For OAuth integrations like Square it will be an OAuth url, for Simphony it will be a 7R page to enter credentials
  setupLink: string
}

export function POSICard({ logo, cardSummary, title, docLink, setupLink }: POSICardData) {
  const { formatMessage } = useLocales()
  return (
    <HStack pt="m" height="140px">
      <VStack
        spacing="s"
        p="m"
        flex={3}
        backgroundColor="primaryBackground"
        borderTopLeftRadius="s"
        borderBottomLeftRadius="s"
        justifyContent="space-between"
      >
        <HStack spacing="xs" justifyContent="space-between">
          <Text textStyle="body1Bold">{title}</Text>
          <Box minWidth="76px" color="secondaryFont">
            <Link href={docLink} target="_blank" color={`${colors.secondaryFont} !important`} fontSize="14px" underline="always">
              {formatMessage(messages.learnMoreLabel)}
            </Link>
          </Box>
        </HStack>
        <Text textStyle="body1" color="secondaryFont">
          {cardSummary}
        </Text>
        <Text>
          {setupLink
            ? formatMessage(messages.setupLabel, {
                a: (chunks: string[]) => (
                  <Anchor target="_self" href={setupLink}>
                    {chunks}
                  </Anchor>
                ),
              })
            : formatMessage(messages.contactSupportLabel, {
                a: (chunks: string[]) => <Anchor href={customerSupportLink}>{chunks}</Anchor>,
              })}
        </Text>
      </VStack>
      <VStack
        width="90px"
        p="m"
        alignItems="center"
        backgroundColor="infoBackground"
        borderTopRightRadius="s"
        borderBottomRightRadius="s"
        justifyContent="center"
      >
        <PreviewImage src={logo || ''} alt="pos integration logo placeholder" maxHeight="100%" maxWidth="100%" />
      </VStack>
    </HStack>
  )
}
