import { useMemo, useCallback } from 'react'
import { usePutVenueUserMutation, useSuspendUserMutation, useUnsuspendUserMutation, useGetVenueUserQuery } from '@sevenrooms/core/api'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales, FormatService } from '@sevenrooms/core/locales'
import { useNavigation, useParams } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { FormInput, FormToggle, Button, Label } from '@sevenrooms/core/ui-kit/form'
import { VStack, HStack, Box, Breadcrumbs, BaseSection, Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPageContent, SettingsPageMeta } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { PermissionSet } from './PermissionSet'
import { type userAccountsFormSchemaType, useUserAccountsFormSchema } from './UserAccountsFormSchema.zod'
import { userAccountsLocales } from './UserAccountsLocales'

export function UserAccountsSettingsDetailed() {
  const { formatMessage } = useLocales()
  const { venueUrlKey, venueId, venueSettings } = useAppContext()
  const nav = useNavigation()
  const { id } = useParams<{ id: string }>()
  const { data, isFetching, refetch } = useGetVenueUserQuery({ venueId, venueUserId: id })
  const schema = useUserAccountsFormSchema()
  const [suspendUser, { isLoading: isSuspendUserLoading }] = useSuspendUserMutation()
  const [unsuspendUser, { isLoading: isUnsuspendUserLoading }] = useUnsuspendUserMutation()
  const [putVenueUser, { isLoading: isPutVenueUserMutationLoading }] = usePutVenueUserMutation()

  const defaultValues = useMemo(
    () => ({
      firstName: data?.user.firstName || '',
      lastName: data?.user.lastName || '',
      email: data?.user.email || '',
      jobTitle: data?.jobTitle || '',
      receiveFeedbackSummaryEmail: data?.receiveFeedbackSummaryEmail || false,
      isMfaEnabled: data?.user.isMfaEnabled || false,
      accessClientTab: data?.extraPermissions.accessClientTab || 0,
      accessMarketingTools: data?.extraPermissions.accessMarketingTools || 0,
      accessPrivateLine: data?.extraPermissions.accessPrivateLine || 0,
      viewReporting: data?.extraPermissions.viewReporting || 0,
      addRemoveClientTagsFromClientProfiles: data?.extraPermissions.addRemoveClientTagsFromClientProfiles || 0,
      manageClientsTagsList: data?.extraPermissions.manageClientsTagsList || 0,
      exportClientData: data?.extraPermissions.exportClientData || 0,
      manageReservationTagsLists: data?.extraPermissions.manageReservationTagsLists || 0,
      manageGlobalAutoTags: data?.extraPermissions.manageGlobalAutoTags || 0,
      viewAllClientsContactInfo: data?.extraPermissions.viewAllClientsContactInfo || 0,
      viewClientsSpendAggregatesData: data?.extraPermissions.viewClientsSpendAggregatesData || 0,
      viewClientsAssociatedWithAllVenuesInTheGroup: data?.extraPermissions.viewClientsAssociatedWithAllVenuesInTheGroup || 0,
      viewPosCheckData: data?.extraPermissions.viewPosCheckData || 0,
      createEditEmailCampaignsWithGlobalTags: data?.extraPermissions.createEditEmailCampaignsWithGlobalTags || 0,
      writeMessageInPrivateLine: data?.extraPermissions.writeMessageInPrivateLine || 0,
      administrateUserPermissions: data?.extraPermissions.administrateUserPermissions || 0,
      administrateGeneralSettings: data?.extraPermissions.administrateGeneralSettings || 0,
      administrateWidgetSettings: data?.extraPermissions.administrateWidgetSettings || 0,
    }),
    [data?.extraPermissions, data?.jobTitle, data?.receiveFeedbackSummaryEmail, data?.user]
  )

  const { field, handleSubmit } = useForm(schema, {
    values: defaultValues,
  })

  const fullName = useMemo(
    () => FormatService.formatFullName(data?.user?.firstName || '', data?.user?.lastName || '', venueSettings?.reservationDisplayOrder),
    [data?.user?.firstName, data?.user?.lastName, venueSettings?.reservationDisplayOrder]
  )

  const labelTitle = (label: string, isRequired: boolean | null = true) => {
    if (!label) {
      return ''
    }
    switch (isRequired) {
      case false:
        return `${label} (${formatMessage(commonMessages.optional)})`
      default:
        return label
    }
  }

  const onSuspendUser = useCallback(() => {
    if (data?.user.id) {
      suspendUser({ venueId, userId: data.user.id }).then(() => {
        refetch()
      })
    }
  }, [data?.user.id, refetch, suspendUser, venueId])

  const onUnsuspendUser = useCallback(() => {
    if (data?.user.id) {
      unsuspendUser({ venueId, userId: data.user.id }).then(() => {
        refetch()
      })
    }
  }, [data?.user.id, refetch, unsuspendUser, venueId])

  const onSave = useCallback(
    (formData: userAccountsFormSchemaType) => {
      if (data?.id) {
        putVenueUser({
          venueId,
          venueUserId: data.id,
          params: {
            user: {
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              isMfaEnabled: formData.isMfaEnabled,
            },
            jobTitle: formData.jobTitle,
            receiveFeedbackSummaryEmail: formData.receiveFeedbackSummaryEmail,
            extraPermissions: {
              accessClientTab: formData.accessClientTab,
              accessMarketingTools: formData.accessMarketingTools,
              accessPrivateLine: formData.accessPrivateLine,
              viewReporting: formData.viewReporting,
              addRemoveClientTagsFromClientProfiles: formData.addRemoveClientTagsFromClientProfiles,
              manageClientsTagsList: formData.manageClientsTagsList,
              exportClientData: formData.exportClientData,
              manageReservationTagsLists: formData.manageReservationTagsLists,
              manageGlobalAutoTags: formData.manageGlobalAutoTags,
              viewAllClientsContactInfo: formData.viewAllClientsContactInfo,
              viewClientsSpendAggregatesData: formData.viewClientsSpendAggregatesData,
              viewClientsAssociatedWithAllVenuesInTheGroup: formData.viewClientsAssociatedWithAllVenuesInTheGroup,
              viewPosCheckData: formData.viewPosCheckData,
              createEditEmailCampaignsWithGlobalTags: formData.createEditEmailCampaignsWithGlobalTags,
              writeMessageInPrivateLine: formData.writeMessageInPrivateLine,
              administrateUserPermissions: formData.administrateUserPermissions,
              administrateGeneralSettings: formData.administrateGeneralSettings,
              administrateWidgetSettings: formData.administrateWidgetSettings,
            },
          },
        }).then(response => {
          if ('error' in response) {
            notify({
              content: formatMessage(userAccountsLocales.notificationSaveError, { message: response.error as string }),
              type: 'error',
            })
          } else {
            notify({ content: formatMessage(userAccountsLocales.notificationSaveSuccess), type: 'success' })
          }
        })
      }
    },
    [data?.id, formatMessage, putVenueUser, venueId]
  )

  const isLoading = useMemo(
    () => isSuspendUserLoading || isUnsuspendUserLoading || isFetching || isPutVenueUserMutationLoading,
    [isSuspendUserLoading, isUnsuspendUserLoading, isFetching, isPutVenueUserMutationLoading]
  )

  return (
    <>
      <SettingsPageMeta title={formatMessage(userAccountsLocales.title)} />
      <SettingsPageContent
        title={fullName}
        actions={
          <HStack spacing="s">
            {data?.user.isActive && (
              <Button variant="secondary-warning" onClick={onSuspendUser} data-test="button-suspend-access" disabled={isLoading}>
                {formatMessage(userAccountsLocales.buttonSuspendAccess)}
              </Button>
            )}
            {!data?.user.isActive && (
              <Button variant="secondary-warning" onClick={onUnsuspendUser} data-test="button-suspend-access" disabled={isLoading}>
                {formatMessage(userAccountsLocales.buttonUnsuspendAccess)}
              </Button>
            )}
            <Button variant="primary" onClick={handleSubmit(onSave)} data-test="button-save-user" disabled={isLoading}>
              {formatMessage(userAccountsLocales.buttonSaveUser)}
            </Button>
          </HStack>
        }
        breadcrumbs={
          <Breadcrumbs>
            <Link to={nav.href(routes.manager2.settings.userAccountsSettings.list, { params: { venueKey: venueUrlKey } })}>
              {formatMessage(userAccountsLocales.title)}
            </Link>
            <Text>{fullName}</Text>
          </Breadcrumbs>
        }
      >
        <Box ml="lm" maxWidth="968px">
          {isLoading ? (
            <Loader />
          ) : (
            <VStack spacing="lm">
              <BaseSection title={formatMessage(userAccountsLocales.detailedUserAccountInformationTitle)}>
                <VStack m="lm" spacing="lm">
                  <HStack spacing="lm" width="100%">
                    <Label primary={labelTitle(formatMessage(userAccountsLocales.detailedUserAccountInformationFirstName))} width="50%">
                      <FormInput field={field.prop('firstName')} inputMode="text" />
                    </Label>
                    <Label primary={labelTitle(formatMessage(userAccountsLocales.detailedUserAccountInformationLastName))} width="50%">
                      <FormInput field={field.prop('lastName')} inputMode="text" />
                    </Label>
                  </HStack>
                  <HStack spacing="lm" width="100%">
                    <Label primary={labelTitle(formatMessage(userAccountsLocales.detailedUserAccountInformationEmail))} width="50%">
                      <FormInput field={field.prop('email')} inputMode="email" />
                    </Label>
                    <Label primary={labelTitle(formatMessage(userAccountsLocales.tableViewJobTile), false)} width="50%">
                      <FormInput field={field.prop('jobTitle')} inputMode="text" />
                    </Label>
                  </HStack>
                  <HStack>
                    <FormToggle
                      label={formatMessage(userAccountsLocales.detailedUserAccountInformationMFA)}
                      field={field.prop('isMfaEnabled')}
                      direction="ltr"
                    />
                  </HStack>
                </VStack>
              </BaseSection>
              <BaseSection title={formatMessage(userAccountsLocales.detailedEmailConfigurationsTitle)}>
                <VStack m="lm" spacing="lm">
                  <HStack>
                    <FormToggle
                      label={formatMessage(userAccountsLocales.detailedEmailConfigurationsFeedbackSummary)}
                      description={formatMessage(userAccountsLocales.detailedEmailConfigurationsFeedbackSummaryDescription)}
                      field={field.prop('receiveFeedbackSummaryEmail')}
                      direction="ltr"
                    />
                  </HStack>
                </VStack>
              </BaseSection>
              <PermissionSet isLoading={isLoading} field={field} />
            </VStack>
          )}
        </Box>
      </SettingsPageContent>
    </>
  )
}
