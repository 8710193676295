import { skipToken } from '@reduxjs/toolkit/query'
import React, { useEffect, useMemo, useState } from 'react'
import {
  useGetPOSIMenuDataQuery,
  useGetPOSIConfigQuery,
  useGetPOSIInServiceStatusesQuery,
  useGetPOSILocationsQuery,
} from '@sevenrooms/core/api'
import {
  type LightspeedKConfig,
  type LightspeedKLocation,
  type POSICheckTriggers,
  type POSIConfig,
  type POSICourseStatus,
  type POSIType,
  POSITypeEnum,
  type SimphonyConfig,
  type SimphonyLocation,
  type SimphonyMenuData,
  type SimphonyMenuItem,
  type SquareConfig,
  type SquareLocation,
} from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { DefaultErrorBanner, SettingsBanner } from '../../components'
import { LoadingSettingsNoHeader } from '../../Loading'
import { posiMetaDataMap } from '../../ReferenceData'
import { messages } from './EditPage.locales'
import { LightspeedKEditPageForm, SimphonyEditPageForm, SquareEditPageForm } from './EditPageForm'
import { BaseEditPageForm } from './EditPageForm/BaseEditPageForm'

export function EditPage() {
  const { formatMessage } = useLocales()
  const { venue, venueId } = useVenueContext()
  const [fetchLocationsAndCheckTriggers, setFetchLocationsAndCheckTriggers] = useState<boolean>(false)
  const posiType = venue.posIntegrationType as POSIType
  const nav = useNavigation()
  const {
    data: config,
    isLoading: isLoadingConfig,
    isFetching: isFetchingConfig,
    refetch: refetchConfig,
    error: configError,
  } = useGetPOSIConfigQuery({ venueId, posiType: venue.posIntegrationType as POSIType })

  const {
    data: inServiceStatuses,
    isLoading: isLoadingInServiceStatuses,
    error: inServiceStatusesError,
  } = useGetPOSIInServiceStatusesQuery({ venueId })

  const getPOSIMenuDataQueryParams = useMemo(() => {
    switch (posiType) {
      case POSITypeEnum.SQUARE:
        return config?.location
          ? {
              venueId,
              locationId: config.location?.id,
              posiType: POSITypeEnum.SQUARE,
            }
          : skipToken
      case POSITypeEnum.SIMPHONY:
        return (config as SimphonyConfig)?.locationId && (config as SimphonyConfig)?.checkPollingRvcs
          ? { venueId, posiType: POSITypeEnum.SIMPHONY }
          : skipToken
      case POSITypeEnum.LIGHTSPEED_K:
        return config?.location
          ? {
              venueId,
              locationId: '',
              posiType: POSITypeEnum.LIGHTSPEED_K,
            }
          : skipToken
      default:
        return skipToken
    }
  }, [config, posiType, venueId])

  const {
    data: menuData,
    isLoading: isLoadingMenuData,
    isFetching: isFetchingMenuData,
    error: menuDataError,
  } = useGetPOSIMenuDataQuery(fetchLocationsAndCheckTriggers ? getPOSIMenuDataQueryParams : skipToken)

  const {
    data: locations,
    isLoading: isLoadingLocations,
    error: locationsError,
    isSuccess: isLocationsSuccess,
  } = useGetPOSILocationsQuery(fetchLocationsAndCheckTriggers ? { venueId, posiType: venue.posIntegrationType as POSIType } : skipToken)

  const isLoading = isLoadingConfig || isLoadingLocations || isLoadingInServiceStatuses || isLoadingMenuData
  const isFetching = isFetchingConfig || isFetchingMenuData

  useEffect(() => {
    if (config) {
      if (config.isAuthTokens) {
        setFetchLocationsAndCheckTriggers(true)
      }
    }
  }, [config, config?.isAuthTokens])

  useEffect(() => {
    if (locationsError) {
      nav.push(routes.manager2.settings.pointOfSaleIntegration.view, { params: { venueKey: venue.urlKey } })
    }
  }, [locationsError, nav, venue.urlKey])

  const courseStatusMappingErrorBanners = useMemo(() => {
    if (menuDataError || inServiceStatusesError) {
      return (
        <VStack spacing="sm" width="100%">
          {menuDataError && (
            <SettingsBanner
              title={formatMessage(messages.checkTriggersErrorBannerTitle)}
              description={formatMessage(messages.checkTriggersErrorBannerDescription, { error: menuDataError as string })}
              type="error"
            />
          )}
          {menuData?.checkTriggers && Object.keys(menuData?.checkTriggers).length === 0 && (
            <SettingsBanner
              title={formatMessage(messages.checkTriggersWarningBannerTitle)}
              description={formatMessage(messages.checkTriggersWarningBannerDescription, {
                a: (chunks: string[]) => <Anchor href={posiMetaDataMap[posiType].docLink}>{chunks}</Anchor>,
              })}
              type="warning"
            />
          )}
          {inServiceStatusesError && (
            <SettingsBanner
              title={formatMessage(messages.inServiceStatusesErrorBannerTitle)}
              description={formatMessage(messages.inServiceStatusesErrorBannerDescription, { error: inServiceStatusesError as string })}
              type="error"
            />
          )}
        </VStack>
      )
    }
    return undefined
  }, [menuDataError, formatMessage, inServiceStatusesError, menuData?.checkTriggers, posiType])

  if (isLoading || !isLocationsSuccess) {
    return <LoadingSettingsNoHeader />
  }

  if (configError || !config) {
    return (
      <BaseEditPageForm posiType={posiType} disabled>
        <DefaultErrorBanner error={configError as string} />
      </BaseEditPageForm>
    )
  }

  switch (venue.posIntegrationType) {
    case POSITypeEnum.SQUARE:
      return (
        <SquareEditPageForm
          config={config as SquareConfig}
          locations={locations as SquareLocation[]}
          refetchConfig={refetchConfig}
          inServiceStatuses={inServiceStatuses ?? getInServiceStatusesFromConfig(config)}
          checkTriggers={menuData?.checkTriggers ?? getCheckTriggersFromConfig(config)}
          isFetching={isFetching}
          courseStatusMappingErrorBanners={courseStatusMappingErrorBanners}
        />
      )
    case POSITypeEnum.LIGHTSPEED_K:
      return (
        <LightspeedKEditPageForm
          config={config as LightspeedKConfig}
          locations={locations as LightspeedKLocation[]}
          refetchConfig={refetchConfig}
          isFetching={isFetching}
          inServiceStatuses={inServiceStatuses ?? getInServiceStatusesFromConfig(config)}
          checkTriggers={menuData?.checkTriggers ?? getCheckTriggersFromConfig(config)}
        />
      )
    case POSITypeEnum.SIMPHONY:
      return (
        <SimphonyEditPageForm
          config={config as SimphonyConfig}
          locations={locations as SimphonyLocation[]}
          refetchConfig={refetchConfig}
          inServiceStatuses={inServiceStatuses ?? getInServiceStatusesFromConfig(config)}
          checkTriggers={menuData?.checkTriggers ?? getCheckTriggersFromConfig(config)}
          menuItems={(menuData as SimphonyMenuData)?.menuItems ?? getMenuItemsFromSimphonyConfig(config as SimphonyConfig)}
          isFetching={isFetching}
          courseStatusMappingErrorBanners={courseStatusMappingErrorBanners}
        />
      )
    default:
      window.location.reload()
      return <></>
  }
}

const getCheckTriggersFromConfig = (config: POSIConfig) => {
  const checkTriggers: POSICheckTriggers = {}
  config.courseStatusMappings.forEach(courseStatusMapping => {
    courseStatusMapping.checkTriggers.forEach(checkTrigger => {
      const existingCheckTriggers = checkTriggers[checkTrigger.type]
      if (existingCheckTriggers) {
        existingCheckTriggers.push(checkTrigger.label)
      } else {
        checkTriggers[checkTrigger.type] = [checkTrigger.label]
      }
    })
  })
  return checkTriggers
}

const getInServiceStatusesFromConfig = (config: POSIConfig) => {
  const inServiceStatuses: POSICourseStatus[] = []
  config.courseStatusMappings.forEach(courseStatusMapping => {
    inServiceStatuses.push(courseStatusMapping.transitionToStatus)
  })
  return inServiceStatuses
}

const getMenuItemsFromSimphonyConfig = (config: SimphonyConfig): SimphonyMenuItem[] => {
  if (config.depositMenuItemId) {
    return [
      {
        menuItemId: config.depositMenuItemId,
        name: `${config.depositMenuItemId}`,
        definitions: config.depositDefinitionSequence
          ? [{ definitionSequence: config.depositDefinitionSequence, name: `${config.depositDefinitionSequence}` }]
          : [],
      },
    ]
  }
  return []
}
