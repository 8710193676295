import { defineMessages } from '@sevenrooms/core/locales'

export const reservationsMessages = defineMessages({
  salutation: {
    id: 'settings.general.salutation',
    defaultMessage: 'Salutation',
  },
  phone: {
    id: 'settings.general.phone',
    defaultMessage: 'Phone',
  },
  email: {
    id: 'settings.general.email',
    defaultMessage: 'Email',
  },
  phoneOrEmail: {
    id: 'settings.general.phoneOrEmail',
    defaultMessage: 'Either phone or email',
  },
  lastName: {
    id: 'settings.general.lastName',
    defaultMessage: 'Last name',
  },
  arrivalTime: {
    id: 'settings.general.arrivalTime',
    defaultMessage: 'Arrival Time',
  },
  seatingArea: {
    id: 'settings.general.seatingArea',
    defaultMessage: 'Seating Area',
  },
  seatingAreaAll: {
    id: 'settings.general.seatingAreaAll',
    defaultMessage: 'All',
  },
  sectionReservationBooking: {
    id: 'settings.general.section.reservationBooking',
    defaultMessage: 'Reservation Booking',
  },
  contactInfoTitle: {
    id: 'settings.general.contactInfo.title',
    defaultMessage: 'Guest Contact Information',
  },
  contactInfoDescription: {
    id: 'settings.general.contactInfo.description',
    defaultMessage: 'When booking reservations internally, require certain fields to be entered',
  },
  autoAssignTablesTitle: {
    id: 'settings.general.autoAssignTables.title',
    defaultMessage: 'Auto-assign Tables',
  },
  autoAssignTablesDescription: {
    id: 'settings.general.autoAssignTables.description',
    defaultMessage: 'Enable auto-assign reservation table option, allowing the system to optimize table assignments',
  },
  multiReservationTitle: {
    id: 'settings.general.multiReservation.title',
    defaultMessage: 'Do not allow multiple reservations for the same guest',
  },
  multiReservationDescription: {
    id: 'settings.general.multiReservation.description',
    defaultMessage: 'Prevent a guest from booking multiple reservations within the',
  },
  multiReservationTimeBased: {
    id: 'settings.general.multiReservation.timeBased',
    defaultMessage: 'Duration of another Reservation',
  },
  multiReservationShiftBased: {
    id: 'settings.general.multiReservation.shiftBased',
    defaultMessage: 'Same Shift',
  },
  showProblemReservationsTitle: {
    id: 'settings.general.showProblemReservations.title',
    defaultMessage: 'Show Problem Reservations',
  },
  showProblemReservationsDescription: {
    id: 'settings.general.showProblemReservations.description',
    defaultMessage: 'Indicates major and minor problems with reservations on the reservation list and slideout',
  },
  sectionInternalConfigurations: {
    id: 'settings.general.section.internalConfigurations',
    defaultMessage: 'Internal Configurations',
  },
  seatingAreaTitle: {
    id: 'settings.general.seatingArea.title',
    defaultMessage: 'Default Seating Area for internal search',
  },
  seatingAreaDescription: {
    id: 'settings.general.seatingArea.description',
    defaultMessage: 'When searching for reservation availability internally, choose which seating area should be selected by default',
  },
  showCoversTitle: {
    id: 'settings.general.showCovers.title',
    defaultMessage: 'Show covers booked by seating area and time',
  },
  showCoversDescription: {
    id: 'settings.general.showCovers.description',
    defaultMessage:
      'When searching for availability in specific seating areas, also show covers booked at each time slot for that seating area',
  },
  sectionReservationPriorityAlerts: {
    id: 'settings.general.section.reservationPriorityAlerts',
    defaultMessage: 'Priority Alerts',
  },
  priorityAlertsOptsTitle: {
    id: 'settings.general.priorityAlerts.title',
    defaultMessage: 'Automatically send Alerts when a reservation is available',
  },
  priorityAlertsDescription: {
    id: 'settings.general.priorityAlerts.description',
    defaultMessage: 'Set the party size range for reservation requests to be fulfilled via automatic Alerts sent to guests.',
  },
  priorityAlertsQuietHoursTitle: {
    id: 'settings.general.priorityAlertsQuietHours.title',
    defaultMessage: 'Quiet hours for sending SMS to clients',
  },
  priorityAlertsQuietHoursDescription: {
    id: 'settings.general.priorityAlertsQuietHours.description',
    defaultMessage: 'SMS will not be sent between 9PM and 8AM. Clients will still receive Priority Alerts via email during dark hours',
  },
  tieredPriorityAlertsOptsTitle: {
    id: 'settings.general.tieredPriorityAlerts.title',
    defaultMessage: 'Prioritize who receives Alerts first',
  },
  sectionReservationWaitTime: {
    id: 'settings.general.section.reservationWaitTime',
    defaultMessage: 'Wait List',
  },
  waitTimeOptsTitle: {
    id: 'settings.general.waitTimeOpts.title',
    defaultMessage: 'Waitlist Wait Time Options',
  },
  waitTimeOptsDescription: {
    id: 'settings.general.waitTimeOpts.description',
    defaultMessage: 'Define the wait time values for waitlist entries that are booked by you and your team internally',
  },
  maxWaitTimeTitle: {
    id: 'settings.general.maxWaitTime.title',
    defaultMessage: 'Maximum wait time:',
  },
  withIntervalTitle: {
    id: 'settings.general.withInterval.title',
    defaultMessage: 'With interval:',
  },
  withIntervalInfoTitle: {
    id: 'settings.general.withInterval.info.title',
    defaultMessage: 'Wait Time Interval',
  },
  withIntervalInfo: {
    id: 'settings.general.withInterval.info',
    defaultMessage:
      'The wait time interval defines the interval that will be quoted to the guest. For example, if the interval is 5 minutes, a guest could be quoted a 45, 50, or 55 minute wait time. If the interval is 10 minutes the guest could be quoted a 40, 50 or 60 minute wait time.',
  },
  autoRemoveTimingTitle: {
    id: 'settings.general.autoRemoveTiming.title',
    defaultMessage: 'Waitlist Auto-Remove Timing',
  },
  autoRemoveTimingDescription: {
    id: 'settings.general.autoRemoveTiming.description',
    defaultMessage:
      'Automatically remove waitlist entries that have been marked as cancelled by the guest from the standard app view, after a designated period',
  },
  sectionReservationConfirmation: {
    id: 'settings.general.section.reservationConfirmation',
    defaultMessage: 'Reservation Confirmation',
  },
  autoConfirmReservationsTitle: {
    id: 'settings.general.autoConfirmReservations.title',
    defaultMessage: 'Auto-confirm Reservations',
  },
  autoConfirmReservationsDescription: {
    id: 'settings.general.autoConfirmReservations.description',
    defaultMessage: 'Reservations booked will be automatically set to confirmed status if booked on or after',
  },
  reservationsEtaTitle: {
    id: 'settings.general.reservationsEta.title',
    defaultMessage: 'Reservation Est. Arrival Window',
  },
  reservationsEtaDescription: {
    id: 'settings.general.reservationsEta.description',
    defaultMessage: 'When reservations are booked, estimated arrival time must fall within the following window',
  },
  reservationsEtaFrom: {
    id: 'settings.general.reservationsEtaFrom',
    defaultMessage: 'From',
  },
  reservationsEtaTo: {
    id: 'settings.general.reservationsEtaTo',
    defaultMessage: 'To',
  },
  reservationsEtaInterval: {
    id: 'settings.general.reservationsEtaInterval',
    defaultMessage: 'With Interval (mins)',
  },
  startDayTitle: {
    id: 'settings.general.startDay.title',
    defaultMessage: 'Start of Day Time',
  },
  startDayDescription: {
    id: 'settings.general.startDay.description',
    defaultMessage: 'New Days begin at this time',
  },
  glistTallyTitle: {
    id: 'settings.general.glistTally.title',
    defaultMessage: 'Guestlist Check in Breakdown',
  },
  glistTallyDescription: {
    id: 'settings.general.glistTally.description',
    defaultMessage:
      'Enable breakdown for different cover charges and genders on guestlist. <i>Note: Changes will appear in mobile apps within 6 hours. To reflect changes immediately, logout of your iOS app and log back in again.</i>',
  },
  byGenderCover: {
    id: 'settings.general.gendercover',
    defaultMessage: 'By Gender and Cover Charge',
  },
  byGender: {
    id: 'settings.general.gender',
    defaultMessage: 'By Gender Only',
  },
  byCover: {
    id: 'settings.general.gender',
    defaultMessage: 'By Cover Charge',
  },
  no: {
    id: 'settings.general.no',
    defaultMessage: 'No',
  },
  chargeTallyTitle: {
    id: 'settings.general.chargeTally.title',
    defaultMessage: 'Tally Charge Breakdown',
  },
  chargeTallyDescription: {
    id: 'settings.general.chargeTally.description',
    defaultMessage:
      'Enable tally by different cover charges. <i>Note: Changes will appear in mobile apps within 6 hours. To reflect changes immediately, logout of your iOS app and log back in again.</i>',
  },
})
