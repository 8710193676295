import React, { type PropsWithChildren } from 'react'
import type { POSIType } from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPage, SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { posiMetaDataMap } from '../../../../ReferenceData'
import { AuthenticationStatusCard } from '../../../AuthenticationStatusCard'
import { messages } from './BaseEditPageForm.locales'

export interface BaseEditPageFormProps {
  posiType: POSIType
  disabled?: boolean
}

export function BaseEditPageForm({ children, posiType, disabled }: PropsWithChildren<BaseEditPageFormProps>) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()

  const handleCancel = () => {
    nav.push(routes.manager2.settings.pointOfSaleIntegration.view, { params: { venueKey: venue.urlKey } })
  }

  return (
    <SettingsPage>
      <SettingsPageMeta title={formatMessage(messages.tabTitle)} />
      <SettingsPageContent
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description)}
        secondHeaderMaxWidth="1000px"
        actions={
          <HStack spacing="s">
            <Button data-test="settings-cancel-button" variant="tertiary" onClick={handleCancel}>
              {formatMessage(commonMessages.cancel)}
            </Button>
            <Button data-test="settings-save-button" type="submit" disabled={disabled}>
              {formatMessage(commonMessages.save)}
            </Button>
          </HStack>
        }
      >
        <Box width="1024px" pl="lm">
          <VStack spacing="sm" flexWrap="wrap">
            <AuthenticationStatusCard
              isAuthenticated
              logo={posiMetaDataMap[posiType].logo}
              title={posiMetaDataMap[posiType].title}
              docLink={posiMetaDataMap[posiType].docLink}
            />
            {children}
          </VStack>
        </Box>
      </SettingsPageContent>
    </SettingsPage>
  )
}
