import * as React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Flex, HStack, PreviewImage, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { CustomerSupportLink, IntegrationsMapping } from '../../Integrations'
import { messages } from './AdditionalPaymentIntegrationCard.locales'

export interface IntegrationCardData {
  provider: string
  providerData: string[]
}

export function AdditionalPaymentIntegrationCard({ provider, providerData }: IntegrationCardData) {
  const { formatMessage } = useLocales()
  const integrationAttributes = IntegrationsMapping[provider]
  const nav = useNavigation()
  const { venueKey } = useVenueContext()

  if (!integrationAttributes) {
    return null
  }

  const onSetUpIntegration = () => {
    nav.push(routes.manager2.settings.paymentIntegration.edit, {
      params: { provider, venueKey },
    })
  }

  return (
    <HStack pt="m">
      <Flex
        flex={1}
        minWidth="33%"
        p="m"
        backgroundColor="infoBackground"
        borderTopRightRadius="s"
        borderBottomRightRadius="s"
        justifyContent="center"
      >
        <PreviewImage
          src={integrationAttributes.logo || ''}
          alt="integration logo placeholder"
          data-test="payment-settings-integration-logo"
          maxHeight="80px"
          maxWidth="100px"
        />
      </Flex>
      <VStack spacing="s" p="m" flex={3} backgroundColor="primaryBackground" borderTopLeftRadius="s" borderBottomLeftRadius="s">
        <HStack spacing="xs">
          <Text textStyle="body1Bold">{integrationAttributes.title}</Text>
          <Button
            variant="tertiary"
            onClick={() => window.open(integrationAttributes.doc)}
            data-test={`payment-settings-provider-documentation-btn-${integrationAttributes.title.toLowerCase()}`}
          >
            <Text textStyle="body1" textDecoration="underline" color="secondaryFont">
              Learn more
            </Text>
          </Button>
        </HStack>
        <Text textStyle="body1" color="secondaryFont">
          {providerData.join(', ')}
        </Text>
        <br />
        <Text>
          {integrationAttributes.isSelfServeDisabled
            ? formatMessage(messages.contactSupport, {
                a: (chunks: string[]) => <Anchor href={CustomerSupportLink}>{chunks}</Anchor>,
              })
            : formatMessage(messages.setupLabel, {
                a: (chunks: string[]) => (
                  <Anchor
                    data-test={`payment-settings-setup-integration-btn-${integrationAttributes.title.toLowerCase()}`}
                    onClick={onSetUpIntegration}
                  >
                    {chunks}
                  </Anchor>
                ),
              })}
        </Text>
      </VStack>
    </HStack>
  )
}
