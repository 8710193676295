import React, { useMemo } from 'react'
import {
  type POSICheckTriggers,
  type POSICourseStatus,
  POSITypeEnum,
  type SimphonyConfig,
  type SimphonyLocation,
  type SimphonyMenuItem,
} from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { SimphonyAuthenticationCard } from '../../../AuthenticationCard'
import { SimphonyAutomaticCheckCreationCard } from '../../../AutomaticCheckCreationCard'
import { SimphonyAutomaticPrepaymentRedemptionCard } from '../../../AutomaticPrepaymentRedemptionCard'
import { CourseStatusMappingCard } from '../../../CourseStatusMappingCard'
import { SimphonyLocationSelectionCard } from '../../../LocationSelectionCard/SimphonyLocationSelectionCard'
import { BaseEditPageForm } from '../BaseEditPageForm'
import { useSavePOSIConfig } from '../hooks'
import {
  convertSimphonyEditPageFormDataToSimphonyConfig,
  getSimphonyEditPageFormDefaultValues,
  type SimphonyEditPageFormData,
  useSimphonyEditPageForm,
} from './SimphonyEditPageForm.zod'
import type { EditPageFormProps } from '../EditPageForm'

export interface SimphonyEditPageFormProps extends EditPageFormProps {
  config: SimphonyConfig
  locations: SimphonyLocation[]
  inServiceStatuses: POSICourseStatus[]
  checkTriggers?: POSICheckTriggers
  menuItems: SimphonyMenuItem[]
  isFetching: boolean
  refetchConfig: () => void
  courseStatusMappingErrorBanners?: React.ReactNode
}

export function SimphonyEditPageForm({
  config,
  locations,
  inServiceStatuses,
  checkTriggers,
  menuItems,
  isFetching,
  refetchConfig,
  courseStatusMappingErrorBanners,
}: SimphonyEditPageFormProps) {
  const defaultValues = useMemo(() => getSimphonyEditPageFormDefaultValues(config), [config])
  const { saveConfig, isSaving } = useSavePOSIConfig()

  const form = useForm(useSimphonyEditPageForm(), {
    defaultValues,
  })
  const { field, formState } = form
  const { isDirty } = formState

  const disabled = useMemo(() => !isDirty || isSaving || isFetching, [isDirty, isSaving, isFetching])

  const handleSave = async (data: SimphonyEditPageFormData) => {
    await saveConfig({
      posiType: POSITypeEnum.SIMPHONY,
      config: convertSimphonyEditPageFormDataToSimphonyConfig({ config, data }),
      refetchConfig,
    })
  }

  const isLocation = !!config.locationId

  return (
    <Form {...form} onSubmit={data => handleSave(data)} onInvalid={() => {}}>
      <BaseEditPageForm posiType={POSITypeEnum.SIMPHONY} disabled={disabled}>
        <SimphonyAuthenticationCard field={field.prop('authenticationCard')} />
        <SimphonyLocationSelectionCard field={field.prop('locationCard')} locations={locations} />
        <SimphonyAutomaticCheckCreationCard field={field.prop('automaticCheckCreationCard')} locations={locations} />
        <SimphonyAutomaticPrepaymentRedemptionCard field={field.prop('automaticPrepaymentRedemptionCard')} menuItems={menuItems} />
        <CourseStatusMappingCard
          field={field.prop('courseStatusMappingCard')}
          inServiceStatuses={inServiceStatuses}
          isFetching={isFetching}
          checkTriggers={checkTriggers}
          posiType={POSITypeEnum.SIMPHONY}
          errorBanners={courseStatusMappingErrorBanners}
          isLocation={isLocation}
        />
      </BaseEditPageForm>
    </Form>
  )
}
