import { useCallback, useState } from 'react'
import mergeRefs from 'react-merge-refs'
import type { LanguageCode } from '@sevenrooms/core/api'
import type { ReservationWidgetSettingsLanguageString } from '@sevenrooms/core/domain'
import { useWatch, type Field } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Link, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { useOverflowed, useScrollController, useThrottledResizeObserver } from '@sevenrooms/core/ui-kit/hooks'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  DividerLine,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  type ModalHeaderProps,
  ModalTitle,
} from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { OtherLanguagesTable } from '../OtherLanguagesTable'
import { otherLanguagesModalMessages } from './OtherLanguagesModal.locales'
import type { GeneralForm } from '../../ReservationSettings.zod'

export interface OtherLanguagesModalProps {
  field: Field<GeneralForm['languageStrings']>
  onClose?: NonNullable<ModalHeaderProps['onClose']>
  onSave: () => void
  enablePrivateEventsBanner: boolean
}

export function OtherLanguagesModal({ field, onSave, onClose, enablePrivateEventsBanner }: OtherLanguagesModalProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venue } = useVenueContext()
  const { ref: resizeRef, height: wrapperHeight } = useThrottledResizeObserver(50, 'border-box')
  const { ref: scrollRef, canScrollDown, canScrollUp } = useScrollController(undefined, 'smooth')
  const [isOverflowed, overflowRef] = useOverflowed(wrapperHeight)
  const languageStrings = useWatch(field)
  const [updatedLanguageStrings, setUpdatedLanguageStrings] = useState(languageStrings)

  const onLanguageStringChange = useCallback(
    (value: string, language: LanguageCode, stringToChange: ReservationWidgetSettingsLanguageString) => {
      const languageStrings = {
        ...updatedLanguageStrings,
        [language]: {
          ...updatedLanguageStrings[language],
          [stringToChange]: value,
        },
      }
      setUpdatedLanguageStrings(languageStrings)
    },
    [updatedLanguageStrings]
  )

  const onSaveClick = useCallback(() => {
    field.set(updatedLanguageStrings)
    onSave()
  }, [field, onSave, updatedLanguageStrings])

  return (
    <Modal ariaLabel="Modal" data-test="sr-other-languages-modal">
      <ModalHeader onClose={onClose} position="relative" boxShadow={isOverflowed && canScrollUp ? 'tertiary' : 'none'}>
        <ModalTitle
          title={formatMessage(otherLanguagesModalMessages.otherLanguagesModalHeaderLabel)}
          subTitle={formatMessage(otherLanguagesModalMessages.otherLanguagesModalHeaderDescription, {
            link: (chunks: string[]) => (
              <Link to={nav.href(routes.manager2.settings.widgets.reservation, { params: { venueKey: venue.urlKey } })} target="_blank">
                {chunks}
                &nbsp;
                <Icon name="VMSWeb-open-in-new" size="sm" />
              </Link>
            ),
          })}
          data-test="sr-other-languages-modal-title"
        />
      </ModalHeader>
      {isOverflowed && <DividerLine margin="none" />}
      <ModalBody data-test="sr-other-languages-modal-body" ref={mergeRefs([overflowRef, resizeRef, scrollRef])}>
        <Box m={isOverflowed ? 'm none' : 'none'}>
          <OtherLanguagesTable
            languageStrings={updatedLanguageStrings}
            onChange={onLanguageStringChange}
            enablePrivateEventsBanner={enablePrivateEventsBanner}
          />
        </Box>
      </ModalBody>
      {isOverflowed && <DividerLine margin="none" />}
      <ModalFooter p="m lm" position="relative" boxShadow={isOverflowed && canScrollDown ? 'tertiaryUp' : 'none'}>
        <ModalActions>
          <Button variant="secondary" onClick={onClose} data-test="sr-other-languages-modal-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary" onClick={onSaveClick} data-test="sr-other-languages-modal-save">
            {formatMessage(commonMessages.save)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
