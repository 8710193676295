import { useMemo } from 'react'
import type { SubscriptionWidgetSettings } from '@sevenrooms/core/domain'
import { z, useForm } from '@sevenrooms/core/form'
import { useImageForm } from '@sevenrooms/core/ui-kit/form'
import { colors } from '@sevenrooms/core/ui-kit/theme'

const RADIO_OPTIONS = ['hidden', 'required', 'optional'] as const

const defaultFormValues: Partial<SubscriptionWidgetSettings> = {
  birthday: 'hidden',
  buttonId: 1,
  buttonColor: colors.primaryNavigation,
  colorPrimary: colors.darkFont,
  dietaryRestrictions: 'hidden',
  headerImageIsResWidgetImage: true,
  postalCode: 'hidden',
  recaptchaOn: false,
  salutation: 'hidden',
  showHeaderImage: false,
  showOptInMarketingVenuePolicy: false,
  showOptInMarketingVenuePolicyDefault: false,
  showOptInMarketingVenueGroupPolicy: false,
  showOptInMarketingVenueGroupPolicyDefault: false,
  showOptInSmsMarketingVenuePolicy: false,
  showOptInSmsMarketingVenuePolicyDefault: false,
  textButton: 'Aa',
  redirectUrl: '',
} as const

export type SubscriptionFormSchema = z.infer<ReturnType<typeof useSubscriptionFormSchema>>

export const useSubscriptionFormSchema = () => {
  const imageSchema = useImageForm()

  return useMemo(
    () =>
      z.object({
        birthday: z.enum(RADIO_OPTIONS),
        buttonColor: z.string().min(1),
        buttonId: z.number().min(1).max(6),
        colorPrimary: z.string().min(1),
        customButtonText: z.string().min(1),
        dietaryRestrictions: z.enum(['hidden', 'optional']),
        headerImage: imageSchema.optional().nullable(),
        headerImageIsResWidgetImage: z.boolean(),
        logo: imageSchema.optional().nullable(),
        logoIsVenueLogo: z.boolean(),
        postalCode: z.enum(RADIO_OPTIONS),
        recaptchaOn: z.boolean(),
        salutation: z.enum(RADIO_OPTIONS),
        showHeaderImage: z.boolean(),
        showOptInMarketingVenuePolicy: z.boolean(),
        showOptInMarketingVenuePolicyDefault: z.boolean(),
        showOptInMarketingVenueGroupPolicy: z.boolean(),
        showOptInMarketingVenueGroupPolicyDefault: z.boolean(),
        showOptInSmsMarketingVenuePolicy: z.boolean(),
        showOptInSmsMarketingVenuePolicyDefault: z.boolean(),
        displayLinkToTheSubscriptionWidgetOnEmailConfirmations: z.boolean(),
        textButton: z.string().min(1),
        redirectUrl: z.string().max(0, 'Invalid url').or(z.string().url().optional().nullable()),
      }),
    [imageSchema]
  )
}

export const useSubscriptionForm = ({
  customButtonText,
  defaultSubscriptionFormValues,
}: {
  customButtonText?: string
  defaultSubscriptionFormValues?: SubscriptionWidgetSettings
}) => {
  const subscriptionFormSchema = useSubscriptionFormSchema()
  const subscriptionForm = useForm(subscriptionFormSchema, {
    defaultValues: {
      customButtonText,
      ...defaultFormValues,
      ...defaultSubscriptionFormValues,
    },
  })
  return subscriptionForm
}
