import { type ReactElement, useMemo } from 'react'
import { Route, Router, Switch } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { AccessRuleBulkEdit } from '@sevenrooms/mgr-access-rule-bulk-edit'
import { SettingsPage, useNavigationPermissionsContext, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { IVvyTableMapping } from '@sevenrooms/mgr-ivvi-settings'
import { ApiAudienceList, ApiAudienceAddEdit } from '@sevenrooms/mgr-settings-custom-audiences'
import { VenueSettings } from '@sevenrooms/mgr-settings-general/VenueSettings'
import { JobsDashboard } from '@sevenrooms/mgr-settings-jobs/JobsDashboard'
import { RoomNumbersList } from '@sevenrooms/mgr-settings-ordering-room-numbers'
import { PaymentIntegrationsPage, EditPaymentIntegrationPage } from '@sevenrooms/mgr-settings-payments'
import { HomePage } from '@sevenrooms/mgr-settings-point-of-sale'
import { SimphonyAuthenticatePage } from '@sevenrooms/mgr-settings-point-of-sale/Authenticate'
import { EditPage } from '@sevenrooms/mgr-settings-point-of-sale/Edit/EditPage'
import { ReservationWidgetSettings } from '@sevenrooms/mgr-settings-reservation-widget'
import { SubscriptionWidgetSettings } from '@sevenrooms/mgr-settings-subscription-widget/SubscriptionWidgetSettings'
import { UserAccountsSettings, UserAccountsSettingsDetailed } from '@sevenrooms/mgr-settings-user-accounts/UserAccountsSettings'
import { useNavigationItems } from './hooks'

export interface SettingsProps {
  renderOldSettingsLayout: () => ReactElement
}

export function Settings({ renderOldSettingsLayout }: SettingsProps) {
  const { venue } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const navigationItems = useNavigationItems()
  const permissions = useNavigationPermissionsContext()

  const canAccessIntegrations = useMemo(() => {
    if (permissions) {
      return permissions?.canAccessSettingsNav && permissions?.canManageRestrictedSettings && !permissions?.isOnboardingSpecialist
    }
    return false
  }, [permissions])

  if (!venue || !permissions) {
    return null
  }

  const canAccessOrdering = venue.isSevenRoomsOrderingEnabled && permissions.canAccessSettingsNav && permissions.canManageRestrictedSettings
  return (
    <Router>
      <SettingsPage showSideNavigation sideNavigationItems={navigationItems}>
        <Switch>
          {(permissions.canAccessSettingsNav ||
            permissions.canAdministrateShiftsAccessRules ||
            permissions.canAdministrateBlackoutDatesDailyProgram) && (
            <Switch>
              {permissions.canAdministrateGeneralSettings && <Route path={routes.manager2.settings.venue.path} component={VenueSettings} />}
              {permissions.canAdministrateWidgetSettings && (
                <Route
                  path={routes.manager2.settings.widgetSettings.reservationWidgetSettingsV2.path}
                  component={ReservationWidgetSettings}
                />
              )}
              {permissions.isSuperhero && (
                <Route path={routes.manager2.settings.jobs.path} render={() => <JobsDashboard venueId={venue.id} />} />
              )}

              {venueSettings?.is_bulk_ar_edit_enabled && (
                <Route path={routes.manager2.settings.accessRuleBulkEdit.path} component={AccessRuleBulkEdit} />
              )}

              {canAccessIntegrations && venueSettings?.is_ivvy_enabled && (
                <Route path={routes.manager2.settings.iVvyTableMapping.path} component={IVvyTableMapping} />
              )}

              {(permissions.isEmailMarketingEnabled || permissions.isSmsMarketingEnabled) && (
                <Route path={routes.manager2.settings.subscriptionPage.path} component={SubscriptionWidgetSettings} />
              )}

              {permissions.canManageUserAccountsCrm && (
                <Route path={routes.manager2.settings.userAccountsSettings.path}>
                  <Route exact path={routes.manager2.settings.userAccountsSettings.list.path} component={UserAccountsSettings} />
                  <Route path={routes.manager2.settings.userAccountsSettings.detailed.path} component={UserAccountsSettingsDetailed} />
                </Route>
              )}

              {canAccessIntegrations && permissions.canAdministratePaymentGateways && (
                <Route path={routes.manager2.settings.paymentIntegration.path}>
                  <Route path={routes.manager2.settings.paymentIntegration.view.path} render={() => <PaymentIntegrationsPage />} />
                  <Route path={routes.manager2.settings.paymentIntegration.edit.path} render={() => <EditPaymentIntegrationPage />} />
                </Route>
              )}

              {canAccessIntegrations && permissions.canAdministratePaymentGateways && (
                <Route path={routes.manager2.settings.customAudiences.path}>
                  <Route path={routes.manager2.settings.customAudiences.view.path} render={() => <ApiAudienceList />} />
                  <Route path={routes.manager2.settings.customAudiences.add.path} render={() => <ApiAudienceAddEdit add />} />
                  <Route path={routes.manager2.settings.customAudiences.edit.path} render={() => <ApiAudienceAddEdit add={false} />} />
                </Route>
              )}

              {canAccessOrdering && <Route path={routes.manager2.settings.ordering.roomNumbers.path} component={RoomNumbersList} />}
              <Route path={routes.manager2.settings.pointOfSaleIntegration.path}>
                <Route path={routes.manager2.settings.pointOfSaleIntegration.view.path} render={() => <HomePage />} />
                <Route path={routes.manager2.settings.pointOfSaleIntegration.edit.path} render={() => <EditPage />} />
                <Route path={routes.manager2.settings.pointOfSaleIntegration.auth.path}>
                  <Route
                    path={routes.manager2.settings.pointOfSaleIntegration.auth.simphony.path}
                    render={() => <SimphonyAuthenticatePage />}
                  />
                </Route>
              </Route>
              {renderOldSettingsLayout()}
            </Switch>
          )}
          {renderOldSettingsLayout()}
        </Switch>
      </SettingsPage>
    </Router>
  )
}
