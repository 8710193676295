import { useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Switch, useDestination, useNavigation } from '@sevenrooms/core/navigation'
import { routes } from '@sevenrooms/core/routes'
import { BaseSection, Box, Tabs, Tab, TabList, TabPanel } from '@sevenrooms/core/ui-kit/layout'
import { jobsDashboardMessages } from '../locales'
import { JobsTable } from './JobsTable'

interface JobsProps {
  venueId: string
}

export function Jobs({ venueId }: JobsProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { params, query } = useDestination(routes.manager2.settings.jobs)

  const tabIndex = params.tableType === 'completed' ? 1 : 0
  const [currentPage, setCurrentPage] = useState(typeof query.currentPage === 'undefined' ? 1 : query.currentPage)
  const [rowsPerPage, setRowsPerPage] = useState(typeof query.rowsPerPage === 'undefined' ? 10 : query.rowsPerPage)

  const tabIndexToTableTypeMap: { [key: number]: string } = {
    0: 'processing',
    1: 'completed',
  }

  const updateNav = (tableType: string, newRowsPerPage: number, newCurrentPage: number) => {
    nav.push(routes.manager2.settings.jobs, {
      params: {
        tableType,
        venueKey: params.venueKey,
      },
      query: {
        rowsPerPage: newRowsPerPage,
        currentPage: newCurrentPage,
      },
    })
  }

  const setCurrentPageHandler = (page: number) => {
    setCurrentPage(page)
    updateNav(tabIndexToTableTypeMap[tabIndex] || '', rowsPerPage, page)
  }

  const setRowsPerPageHandler = (numRows: number) => {
    setRowsPerPage(numRows)
    updateNav(tabIndexToTableTypeMap[tabIndex] || '', numRows, currentPage)
  }

  const renderJobsTable = () => (
    <JobsTable
      venueId={venueId}
      completed={tabIndex === 1}
      currentPage={currentPage}
      setCurrentPage={setCurrentPageHandler}
      rowsPerPage={rowsPerPage}
      setRowsPerPage={setRowsPerPageHandler}
    />
  )

  return (
    <BaseSection title={formatMessage(jobsDashboardMessages.jobsTitle)} maxWidth="100%">
      <Box p="lm">
        <Switch>
          <Tabs
            onSelect={index => {
              setCurrentPage(1)
              setRowsPerPage(10)
              updateNav(tabIndexToTableTypeMap[index] || '', rowsPerPage, currentPage)
            }}
            selectedIndex={tabIndex}
          >
            <TabList>
              <Tab data-test="processing-tab">Processing</Tab>
              <Tab data-test="completed-tab">Completed</Tab>
            </TabList>
            <Box pt="m">
              <TabPanel>{renderJobsTable()}</TabPanel>
              <TabPanel>{renderJobsTable()}</TabPanel>
            </Box>
          </Tabs>
        </Switch>
      </Box>
    </BaseSection>
  )
}
